@charset "utf-8";
//*****************TOP*****************//

@media screen and ( max-width : 767px ) {
  #a01, #a02, #a03, #a04, #a05, #a06, #a07, #a08, #a09, #a10, #a11, #a12, #a13, #a14, #a15 {
    padding-top: 60px;
    margin-top: -60px;
  }
}

.main_visual {
  margin-bottom: 0;
  .main_visual_copy {
    > li {
      img {
        @media screen and ( max-width : 767px ) {
          max-width: 750px;
          max-height: 742px;
          left: 0;
          right: auto;
          width: 98%;
        }
        @media screen and ( min-width : 768px ) {
          width: 100%;
          bottom: 40%;
        }
        @media screen and ( min-width : 1250px ) {
          bottom: 20%;
        }
      }
    }
  }
}

.sec_top {
  margin: 0!important;
  h2 {
    text-align: center;
    @include fontsize(23);
    line-height: 1;
    font-weight: bold;
    padding: 0 0 15px;
    margin: 0 0 25px;
    .sec_span {
      display: block;
      padding: 5px 0 0;
      @include fontsize(16);
      color: $color_primary;
    }
    &:before, &:after {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      margin: auto;
      left: 0;
      right: 0;
      top: auto;
      bottom: 0;
      width: 33px;
      height: 5px;
      background: url("/wp-content/uploads/h2.png") no-repeat center / cover;
    }
  }
  .button {
    @include fontsize(16);
    padding: 1.4em 1em;
    font-weight: bold;
    max-width: 248px;
  }
  @media screen and ( min-width : 768px ) {
    h2 {
      @include fontsize(28);
      padding: 0 0 15px;
      margin: 0 0 50px;
      .sec_span {
        padding: 5px 0 0;
        @include fontsize(20);
      }
    }
  }
  @media screen and ( min-width : 1250px ) {
    h2 {
      @include fontsize(32);
    }
  }
}

.sec01 {
  padding: 50px 0;
  background: url("/wp-content/uploads/bg_img001.jpg") no-repeat center / cover;
  .sec_no {
    @include fontsize(30);
    line-height: 1;
    font-weight: bold;
    padding-bottom: 5px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color_secondary;
    width: fit-content;
    text-align: center;
    color: #ffffff;
    letter-spacing : 4px;
    text-shadow: 1px  1px 0px $color_secondary,
    -1px  1px 0px $color_secondary,
    1px -1px 0px $color_secondary,
    -1px -1px 0px $color_secondary,
    1px  0px 0px $color_secondary,
    0px  1px 0px $color_secondary,
    -1px  0px 0px $color_secondary,
    0px -1px 0px $color_secondary;
  }
  .sec_title {
    @include fontsize(18);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
  }
  .sec_text {
    @include fontsize(16);
    line-height: 24px;
  }
  @media screen and  ( min-width : 768px ) {
    padding: 100px 0;
    .sec_title {
      @include fontsize(16);
      margin-bottom: 15px;
      min-height: 48px;
    }
    .sec_no {
      @include fontsize(30);
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
  @media screen and ( min-width : 1020px ) {
    .sec_title {
      @include fontsize(18);
    }
  }
}

.sec02 {
  padding: 50px 0;
  background: url("/wp-content/uploads/bg_img002.jpg") no-repeat center / cover;
  @media screen and  ( min-width : 768px ) {
    padding: 100px 0;
  }
}

.sec03 {
  padding: 50px 0;
  .sec_bnr {
    a {
      max-width: 400px;
      height: 60px;
      margin: auto;
      @include fontsize(20);
      text-decoration: none;
      line-height: 1;
      font-weight: bold;
      border-radius: 15px;
      border: 2px solid $color_primary;
      color: $color_primary;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        display: block;
        margin: auto;
        left: auto;
        right: 5%;
        top: 0;
        bottom: 0;
        width: 8px;
        height: 8px;
        border-right: 2px solid $color_primary;
        border-top: 2px solid $color_primary;
        transform: rotate(45deg);
      }
      &:hover {
        background: $color_primary;
        border: 2px solid $color_primary;
        color: $color_text_reversal;
        &:after {
          border-color: #fff;
        }
      }
    }
  }
  @media screen and  ( min-width : 768px ) {
    padding: 100px 0;
    .row {
      max-width: 820px;
      margin: auto;
    }
    .sec_bnr {
      a {
        height: 100px;
        @include fontsize(24);
      }
    }
  }
}

.sec04 {
  padding: 50px 0;
  background: url("/wp-content/uploads/bg_img003.jpg") no-repeat center top;
  .sec_text {
    @include fontsize(16);
    line-height: 32px;
  }
  .more_btn {
    margin-top: 20px;
    text-align: center;
  }
  @media screen and ( max-width : 767px ) {
    h2 {
      line-height: 1.3;
    }
    figure {
      img {
        width: 100%!important;
        height: 100%!important;
      }
    }
  }
  @media screen and  ( min-width : 768px ) {
    padding: 100px 0;
    .row {
      column-gap: 5%;
      align-items: center;
      .col {
        &:first-child {
          width: 49%;
        }
        &:last-child {
          width: 46%;
        }
      }
    }
    .more_btn {
      margin-top: 50px;
      text-align: right;
    }
  }
}

.sec05, .sec06 {
  padding: 50px 0;
  .more_btn {
    margin-top: 25px;
  }
  .content {
    dl {
      width: 100%;
      dt {
        @include fontsize(16);
        color: #868686;
        span {
          @include fontsize(13);
          background: $color_primary;
          border: 1px solid $color_primary;
          color: #fff;
          border-radius: 3px;
          vertical-align: middle;
          &:first-of-type {
            padding-top: 0;
          }
        }
      }
      dd {
        @include fontsize(16);
        position: relative;
        &:after {
          content: '';
          position: absolute;
          display: block;
          margin: auto;
          left: auto;
          right: 2%;
          top: 0;
          bottom: 0;
          width: 8px;
          height: 8px;
          border-right: 2px solid $color_primary;
          border-top: 2px solid $color_primary;
          transform: rotate(45deg);
        }
        a {
          color: #2a2a2a;
        }
      }
    }
  }
  @media screen and  ( max-width : 767px ) {
    dl {
      dd {
        padding-right: 45px;
      }
    }
  }
  @media screen and  ( min-width : 768px ) {
    padding: 100px 0;
    .more_btn {
      margin-top: 50px;
    }
    .content {
      width: 100%;
      padding: 0;
      dl {
        max-height: 450px;
        dd, dt {
          @include fontsize(16);
          padding: 24px 0;
          &:first-of-type {
            padding-top: 0;
          }
        }
        dt {
          margin-right: 10px;
          float: left;
          padding-left: 0;
          span {
            max-width: 130px;
          }
        }
        dd {
          padding-right: 60px;
          padding-left: 210px;
          &:first-of-type {
            border-top: none;
          }
        }
      }
    }
  }
}

.sec06 {
  padding-top: 0;
}

.sec07 {
  padding: 50px 0;

  .more_btn {
    margin-top: 25px;
  }
  .sec_logo {
    @include fontsize(20);
    line-height: 1;
    margin-bottom: 10px;
    font-weight: bold;
    font-family: $font_base_mincho;
  }
  dl {
    margin: 0 0 20px;
    dd {
      padding: 15px 0;
      line-height: 22px;
      border-top: 1px solid #d1d1d1;
      padding-left: 0;
      margin: 0;
      &:first-of-type {
        border-top: 1px solid #d1d1d1;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
  @media screen and  ( max-width : 767px ) {
    position: relative;
    z-index: 0;
    &:after {
      content: '';
      position: absolute;
      display: block;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: auto;
      width: 100%;
      height: 35%;
      background: url("/wp-content/uploads/bg_img002.jpg") no-repeat center top;
      z-index: -1;
    }
    picture {
      margin-bottom: 20px;
    }
  }
  @media screen and  ( min-width : 768px ) {
    padding: 100px 0;
    background: url("/wp-content/uploads/bg_img002.jpg") no-repeat center top;
    .more_btn {
      margin-top: auto;
    }
    .map_wide {
      padding-top: 494px;
    }
    .row:not(:last-child) {
      margin-bottom: 50px;
    }
    .sec_logo {
      @include fontsize(24);
      line-height: 1;
      margin-bottom: 10px;
      padding-top: 15px;
    }
    .row {
      column-gap: 3.3%;
      justify-content: space-between;
      .span_6 {
        &:first-child {
          width: 47.7%;
        }
        &:last-child {
          width: 49%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}


.sec08 {
  padding: 50px 0;
  background: url("/wp-content/uploads/bg_img004.jpg") no-repeat center / cover;
  h2 {
    .sec_span {
      color: #fff;
    }
    &:after {
      background: url("/wp-content/uploads/h2_2.png") no-repeat center / cover;
    }
  }
  .text_box {
    padding: 30px 15px;
    border-radius: 15px;
    background: #fff;
  }
  .sec_title {
    padding: 0 0;
    background: #bcd63f;
    text-align: center;
    color: #fff;
    margin: 0;
    font-weight: bold;
  }
  .sec_tel {
    padding: 10px 0;
    border-bottom: 1px solid #d2dbef;
  }
  .sec_text {
    @include fontsize(16);
    line-height: 20px;
    margin: 10px 0 0;
    text-align: center;
    .sec_span {
      @include fontsize(14);
    }
  }
  @media screen and  ( min-width : 768px ) {
    padding: 100px 0;
    .text_box {
      padding: 32px 15px;
    }
    .sec_text {
      @include fontsize(16);
      .sec_span {
        @include fontsize(14);
      }
    }
  }
  @media screen and ( min-width : 1020px ) {
    .text_box {
      padding: 32px;
    }
    .sec_text {
      @include fontsize(18);
      .sec_span {
        @include fontsize(14);
      }
    }
  }
}