@charset "utf-8";

/* お問い合わせフォーム */
.inquiry {
  .h-adr {
    margin: 0;
    padding: 4%;
    border: $color_highlight_bg solid 5px;
    border-radius: 10px;
  }

  .table-default {
    border: none;

    tr {
      &:not(:last-of-type) {
        border-bottom: 1px solid #cccccc;
      }
    }

    th {
      background: none;
      border: none;
      text-align: left;
      padding: 1.4em 50px 1.2em .5em;
      vertical-align: top;
      font-weight: bold;
      position: relative;
      @media (max-width: 767px) {
        padding: 1em 0 0;
      }

      .required {
        display: inline-block;
        background: #d43f3a;
        color: white;
        padding: 4px 10px;
        border-radius: 3px;
        @include fontsize(12);
        margin-left: .5em;
        flex-shrink: 0;

        @media (min-width: 768px) {
          position: absolute;
          top: 1.6em;
          right: 0;
        }
      }
    }

    td {
      background: none;
      border: none;
      padding: 1.2em .5em;
      @media (max-width: 767px) {
        padding: .5em 0 1em;
      }
    }
  }

  .input_wrap {
    display: block;
    margin: 0;
    padding: 0 .2em;
    @media (max-width: 767px) {
      margin: 0;
    }

    input, textarea {
      margin: 0;
    }

    &:last-child {
      margin-right: auto;
    }
  }

  .text-2col {
    display: flex;

    .input_wrap {
      flex-basis: 50%;
    }
  }

  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  select,
  textarea {
    width: 100%;
    max-width: 100%;
    padding: 7px 2%;
    color: $color_text;
    @include fontsize(16);
    margin: 0 .2em;
    border: 1px solid #cccccc;
    background: #fafafa;
    border-radius: 3px;
    -webkit-appearance: none;
    @media (max-width: 767px) {
      margin: .2em 0;
    }
  }

  input[type="number"] {
    width: 65px;
    text-align: center;
  }

  input.p-postal-code {
    width: 200px;
  }

  input.p-region {
    width: 100px;
  }

  input.p-locality {
    width: 150px;
  }

  input.p-street-address {
    width: 100%;
  }

  input.p-extended-address {
    width: 100%;
  }

  .mwform-tel-field {
    display: flex;
    justify-content: space-between;
  }

  .submit {
    margin: 1em 0;
    text-align: center;
  }

  .form_button {
    @extend .button;
  }
}


@media (min-width: 768px) {
  .inquiry {
    input[type="text"],
    input[type="url"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    input[type="text"],
    select {
      //width: 60%;
      margin: 0 .2em;
    }

    input[type="number"] {
      width: 65px;
    }

    input.p-postal-code {
      width: 200px;
    }

    input.p-region {
      width: 100px;
    }

    input.p-locality {
      width: 150px;
    }

    input.p-street-address {
      width: 100%;
    }

    input.p-extended-address {
      width: 100%;
    }

    textarea {
      width: 100%;
    }

    .input_wrap {
      display: block;
      margin: 0 .2em;

      input, textarea {
        margin: 0;
      }

      &:last-child {
        margin-right: auto;
      }
    }

    .submit {
      margin: 60px 0 0;
    }

    .form_button, .wpcf7-submit {
      background: $color_form_button_bg;
      color: $color_form_button_text;
      border-color: $color_form_border;

      &.form_back {
        background: $color_form_back_button_bg;
        border-color: $color_form_back_border;
        color: $color_form_back_button_text;

        &:hover {
          background: $color_form_back_button_bg_hover;
          border-color: $color_form_back_button_border_hover;
          color: $color_form_back_button_text_hover;
        }
      }

      &.form_send {
        background: $color_form_send_button_bg;
        border-color: $color_form_send_border;
        color: $color_form_send_button_text;

        &:hover {
          background: $color_form_send_button_bg_hover;
          border-color: $color_form_send_button_border_hover;
          color: $color_form_send_button_text_hover;
        }
      }
    }
  }
}

.form_button_wrap {
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  button {
    margin: .5em !important;
  }
}

.privacy_wrap {
  margin-top: 5%;
  border: #ccc solid 1px;
  padding: 1em;
  height: 400px;
  overflow-y: scroll;
}

.mw_wp_form_confirm .input-show {
  display: none !important;
}

.mw_wp_form_input .input-hide {
  display: none !important;
}

.mw_wp_form .error {
  color: #d43f3a !important;
}
