@charset "utf-8";
footer {
  position: relative;
  padding: 100px 0 90px;
  font-size: $footer_font_size+px;
  @media (max-width: 767px) {
    padding: 0 0 1em;
  }

  .fNav {
    ul {
      @include listclear();

      li {
        + li {
          border-top: 1px solid rgba($color_primary, 0.3);
        }

        a {
          display: block;
          padding: 1em 15px;
          text-decoration: none;
        }

        &.menu-item-has-children {
          ul {
            display: block !important;
          }
        }
      }
    }
  }

  .copy_right {
    @include fontsize(10);
  }
}

@media (max-width: 767px) {
  footer {
    ul {
      border-top: 1px solid rgba($color_primary, 0.3);

      li {
        > ul {
          border-top: 1px solid rgba($color_primary, 0.3);
        }

        &.menu-item-has-children {
          ul {
            display: block !important;
            height: auto !important;

            a {
              font-size: 90%;
              padding-left: 1.5em;
            }

            ul {
              a {
                padding-left: 2.5em;
              }
            }
          }
        }
      }
    }

    .col {
      margin-bottom: 0 !important;
    }
  }
}

@media (min-width: 768px) {
  footer {
    .row {
      max-width: 700px;
    }
    .fNav {
      margin-bottom: 5%;
      ul {
        width: 100%;
        text-align: left;
        border-bottom: none;

        li {
          border: none !important;

          a {
            @include fontsize(16);
            padding: .35em 1em .35em 1em !important;
            text-decoration: none;
            position: relative;
            &:hover {
              text-decoration: underline;
            }
            &:before {
              content: '';
              position: absolute;
              display: block;
              margin: auto;
              left: 0;
              right: auto;
              top: 0;
              bottom: 0;
              width: 0;
              height: 0;
              border-style: solid;
              border-color: transparent transparent transparent $color_primary;
              border-width: 3.5px 0px 3.5px 4px;
            }
          }

          > ul {
            padding-left: .2em;

            a {
              font-size: 90%;

              &:before {
                width: 5px;
                height: 5px;
                border: none;
                background: $color_primary;
                border-radius: 50%;
                margin-top: 11px;
              }
            }

            .sub-menu {
              padding-left: 1em;

              a {
                &:before {
                  width: 5px;
                  height: 2px;
                  border: none;
                  background: $color_primary;
                  border-radius: 0;
                  transform: rotate(0);
                }
              }
            }
          }
        }
      }
    }
  }
}

.copy_right {
  background: $color_secondary;
  color: white;
  @include fontsize(15);
  margin: 0;
  border-top: 1px solid #fff;
  padding: 2em 0;
  @media (max-width: 767px) {
    padding: 1em 0;
  }
}

// スマホナビゲーション折り返し設定（タブレット）
@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li {
    &.tablet-break:after {
      content: "\A";
      white-space: pre;
    }

    &.tablet-break + li:before {
      display: none;
    }
  }
}

footer {
  background: $color_footer_bg;
  color: $color_footer_text;

  a {
    color: $color_ftnavlink;

    &:hover {
      color: $color_ftnavlink_hover;
    }
  }

  @media (min-width: 768px) {
    .container {
      .row {
        nav {
          ul {
            display: inline-block;
          }

          a {
            display: inline-block;
            padding: 0.3em;
          }
        }
      }
    }
  }
}