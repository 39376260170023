/*!
  12 COLUMN : RESPONSIVE GRID SYSTEM
  DEVELOPER : DENIS LEBLANC
  URL : http://responsive.gs
  VERSION : 3.0
  LICENSE : GPL & MIT
*/
*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.container {
  max-width: 100%;
  margin: 0 auto
}

.row {
  padding-bottom: 0
}

.col {
  display: block;
  width: 100%
}

@media (min-width: 768px) {
  .span_1 {
    width: 8.33333333333%
  }

  .span_2 {
    width: 16.6666666667%
  }

  .span_3 {
    width: 25%
  }

  .span_4 {
    width: 33.3333333333%
  }

  .span_5 {
    width: 41.6666666667%
  }

  .span_6 {
    width: 50%
  }

  .span_7 {
    width: 58.3333333333%
  }

  .span_8 {
    width: 66.6666666667%
  }

  .span_9 {
    width: 75%
  }

  .span_10 {
    width: 83.3333333333%
  }

  .span_11 {
    width: 91.6666666667%
  }

  .span_12 {
    width: 100%
  }

  .gutters {
    .row {
      column-gap: percentage($grid_column-gap);
      row-gap: $grid_row-gap+px;
    }

    //1グリッド辺りの幅
    $grid_unit: (1 - ($grid_column-gap * 11)) / 12;

    .span_1 {
      //width: 6.5%;
      width: percentage($grid_unit);
    }

    .span_2 {
      //width: 15%;
      width: percentage(($grid_unit * 2) + $grid_column-gap);
    }

    .span_3 {
      //width: 23.5%;
      width: percentage(($grid_unit * 3) + ($grid_column-gap * 2));
    }

    .span_4 {
      //width: 32%;
      width: percentage(($grid_unit * 4) + ($grid_column-gap * 3));
    }

    .span_5 {
      //width: 40.5%;
      width: percentage(($grid_unit * 5) + ($grid_column-gap * 4));
    }

    .span_6 {
      //width: 49%;
      width: percentage(($grid_unit * 6) + ($grid_column-gap * 5));
    }

    .span_7 {
      //width: 57.5%;
      width: percentage(($grid_unit * 7) + ($grid_column-gap * 6));
    }

    .span_8 {
      //width: 66%;
      width: percentage(($grid_unit * 8) + ($grid_column-gap * 7));
    }

    .span_9 {
      //width: 74.5%;
      width: percentage(($grid_unit * 9) + ($grid_column-gap * 8));
    }

    .span_10 {
      //width: 83%;
      width: percentage(($grid_unit * 10) + ($grid_column-gap * 9));
    }

    .span_11 {
      //width: 91.5%;
      width: percentage(($grid_unit * 11) + ($grid_column-gap * 10));
    }

    .span_12 {
      width: 100%;
    }
  }
}

/*!
 12 COLUMN : RESPONSIVE GRID SYSTEM Fix Ver. 1.2.1
*/
.container {
  padding: 0 1%
}

.container img {
  max-width: 100%;
  height: auto;
}

* + .container {
  margin-top: 2em
}

.row:not(:last-child) {
  margin-bottom: 4%
}

.col:not(:last-child) {
  margin-bottom: 3%
}

@media (min-width: 768px) {
  .container {
    max-width: $container+px;
    margin: auto;
    padding: 0 10px
  }

  * + .container {
    margin-top: 40px
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .row.reverse {
    flex-flow: row-reverse;
  }

  .col:not(:last-child) {
    margin-bottom: 0
  }

  .col.offset_1 {
    margin-left: 8.33333333333%
  }

  .col.offset_2 {
    margin-left: 16.6666666667%
  }

  .col.offset_3 {
    margin-left: 25%
  }

  .col.offset_4 {
    margin-left: 33.3333333333%
  }

  .col.offset_5 {
    margin-left: 41.6666666667%
  }

  .col.offset_6 {
    margin-left: 50%
  }

  .col.offset_7 {
    margin-left: 58.3333333333%
  }

  .col.offset_8 {
    margin-left: 66.6666666667%
  }

  .col.offset_9 {
    margin-left: 75%
  }

  .col.offset_10 {
    margin-left: 83.3333333333%
  }

  .col.offset_11 {
    margin-left: 91.6666666667%
  }

  .col.offset_12 {
    margin-left: 100%
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0
  }
}

@media (min-width: 768px) {
  .d-flex {
    display: flex;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-nowrap {
    flex-wrap: nowrap;
  }

  .reverse {
    flex-direction: row-reverse;
    //justify-content: space-between;
  }

  .align-items-start {
    align-items: flex-start;
  }

  .align-items-center {
    align-items: center;
  }

  .align-items-end {
    align-items: flex-end;
  }

  .justify-content-start {
    justify-content: flex-start;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .row {
    &.col_5 {
      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}

//16GRID
.grid_16 {
  .span_1 {
    width: 6.25%;
  }

  .span_2 {
    width: 12.5%;
  }

  .span_3 {
    width: 18.75%;
  }

  .span_4 {
    width: 25%;
  }

  .span_5 {
    width: 31.25%;
  }

  .span_6 {
    width: 37.5%;
  }

  .span_7 {
    width: 43.75%;
  }

  .span_8 {
    width: 50%;
  }

  .span_9 {
    width: 56.25%;
  }

  .span_10 {
    width: 62.5%;
  }

  .span_11 {
    width: 68.75%;
  }

  .span_12 {
    width: 75%;
  }

  .span_13 {
    width: 81.25%;
  }

  .span_14 {
    width: 87.5%;
  }

  .span_15 {
    width: 93.75%;
  }

  .span_16 {
    width: 100%;
  }

  &.gutters {
    //1グリッド辺りの幅
    $grid_unit_16: (1 - ($grid_column-gap * 15)) / 16;

    .span_1 {
      //width: 4.375%;
      width: percentage($grid_unit_16);
    }

    .span_2 {
      //width: 10.75%;
      width: percentage(($grid_unit_16 * 2) + $grid_column-gap);
    }

    .span_3 {
      //width: 17.125%;
      width: percentage(($grid_unit_16 * 3) + ($grid_column-gap * 2));
    }

    .span_4 {
      //width: 23.5%;
      width: percentage(($grid_unit_16 * 4) + ($grid_column-gap * 3));
    }

    .span_5 {
      //width: 29.875%;
      width: percentage(($grid_unit_16 * 5) + ($grid_column-gap * 4));
    }

    .span_6 {
      //width: 36.25%;
      width: percentage(($grid_unit_16 * 6) + ($grid_column-gap * 5));
    }

    .span_7 {
      //width: 42.625%;
      width: percentage(($grid_unit_16 * 7) + ($grid_column-gap * 6));
    }

    .span_8 {
      //width: 49.0%;
      width: percentage(($grid_unit_16 * 8) + ($grid_column-gap * 7));
    }

    .span_9 {
      //width: 55.375%;
      width: percentage(($grid_unit_16 * 9) + ($grid_column-gap * 8));
    }

    .span_10 {
      //width: 61.75%;
      width: percentage(($grid_unit_16 * 10) + ($grid_column-gap * 9));
    }

    .span_11 {
      //width: 68.125%;
      width: percentage(($grid_unit_16 * 11) + ($grid_column-gap * 10));
    }

    .span_12 {
      //74.5%
      width: percentage(($grid_unit_16 * 12) + ($grid_column-gap * 11));
    }

    .span_13 {
      //80.875%
      width: percentage(($grid_unit_16 * 13) + ($grid_column-gap * 12));
    }

    .span_14 {
      //87.25%
      width: percentage(($grid_unit_16 * 14) + ($grid_column-gap * 13));
    }

    .span_15 {
      //93.625%
      width: percentage(($grid_unit_16 * 15) + ($grid_column-gap * 14));
    }

    .span_16 {
      width: 100%;
    }
  }
}