.ofi {
  display: block;
  position: relative;
  overflow: hidden;
  padding-top: 75%;

  &.cover {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.ofi > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
