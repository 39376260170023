@charset "utf-8";
//*****************共通*****************//
.icon-tel {
  &:before {
    content: '\f879';
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;
  }
}

//ロード時のアニメーションチラツキ防止
.fadeUp {
  visibility: hidden;
}

