@charset "utf-8";
.p-sitemap {
  border-top: rgba($color_text, 0.3) solid 1px;

  ul {
    @include listclear();

    li {
      a {
        display: block;
        text-decoration: none;
        position: relative;
        padding: .6em 1em .6em 1.5em;
        border-bottom: rgba($color_text, 0.3) solid 1px;
        @include fontsize(18);

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          border: 0;
          border-top: solid 2px $color_text;
          border-right: solid 2px $color_text;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          left: .5em;
          margin: auto;
        }

        &:hover {
          background: $color_highlight_bg;
        }
      }
    }

    &.children {
      margin: 0;

      li {
        a {
          padding-left: 3em;
          @include fontsize($base_font_size - 1);

          &:before {
            left: 2em;
          }
        }
      }
    }
  }
}
