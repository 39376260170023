@charset "utf-8";

$dots-color: #ccc;
$dots-active-color: $color_primary;

// main_visual slick
.main_visual {
  position: relative;
  margin-bottom: 100px;
  @media (max-width: ($header_break_point - 1)+px) {
    margin-top: $sp_header_height+px;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }

  @if $fixed_header == true {
    margin-top: $sp_header_height+px;
    @media (min-width: $header_break_point+px) {
      margin-top: $header_height+px;
    }
  }

  @if $header_alpha == true {
    margin-top: 0 !important;
  }

  .main_visual_slick, .slick-track {
    @include listclear();
    height: 100% !important;
  }

  .slick-track {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  .slick-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
  }

  .main_visual_slick {
    position: relative;
    margin: 0 auto;

    .slick-slide {
      @include position-center();
      overflow: hidden;

      picture, img {
        @include position-center-wide();
        top: 0;
        width: auto;
        height: 100%;
      }
    }
  }

  .main_visual_copy {
    @include position-center();
    @include listclear();
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    z-index: 53;
    @if $main_slide_copy_sync != false {
      transform: scale(1);
    }

    > li {
      @include position-center();

      img {
        @include position-center();
        width: ($main_slide_copy_width / $container)*100#{'%'};
        max-width: $main_slide_copy_width+px;
        max-height: $main_slide_copy_height+px;
        z-index: 52;
        @media (max-width: ($main_slide_break_point - 1)+px) {
          width: 90%;
        }
      }

      @if $main_slide_copy_sync != false {
        opacity: 0;
        transition: opacity 1s;
        &.active {
          opacity: 1;
        }
      }
    }
  }

  &.wow {
    .main_visual_copy {
      > li {
        img {
          //コピーのアニメーション遷移”前”をここに書く
          &.main_copy1 {
          }

          &.main_copy2 {
          }

          &.main_copy3 {
          }
        }
      }
    }

    &.animated {
      .main_visual_copy {
        > li {
          img {
            &.main_copy1,
            &.main_copy2,
            &.main_copy3 {
              //コピーのアニメーション遷移”後”をここに書く
            }
          }
        }
      }
    }


  }
}

@if $main_slide_type == 'A' {
  .main_visual {
    .main_visual_slick {
      padding-top: ($main_slide_height / $container)*100#{'%!important'};
    }
  }

  @media (min-width: $container+px) {
    .main_visual {
      .main_visual_slick {
        padding-top: 0 !important;
        height: $main_slide_height+px !important;
      }
    }
  }
  @media (min-width: $main_slide_width+px) {
    .main_visual {
      .main_visual_slick {
        padding-top: ($main_slide_height / $main_slide_width)*100#{'%!important'};
      }
    }
  }
  @media (max-width: ($main_slide_break_point - 1)+px) {
    .main_visual {
      .main_visual_slick {
        padding-top: $main_slide_correct#{'%!important'};
      }
    }
  }
}

@if $main_slide_type == 'B' {
  .main_visual {
    .main_visual_slick {
      padding-top: ($main_slide_height / $main_slide_width)*100#{'%!important'};
    }
  }
  @media (max-width: 768px) {
    .main_visual {
      .main_visual_copy {
        > li {
          img {
            width: 90%;
          }
        }
      }
    }
  }
  @media (max-width: ($main_slide_break_point - 1)+px) {
    .main_visual {
      .main_visual_slick {
        padding-top: $main_slide_correct#{'%!important'};
      }
    }
  }
}

@if $main_slide_type == 'C' {
  .main_visual {
    .main_visual_slick {
      @if $header_alpha == true {
        padding-top: 100vh !important;
      } @else {
        padding-top: calc(100vh - #{$header_height}px) !important;
      }
      @media (max-width: ($header_break_point - 1)+px) {
        @if $header_alpha == true {
          padding-top: 100vh !important;
        } @else {
          padding-top: calc(100vh - #{$sp_header_height}px) !important;
        }
      };

      picture {
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;

        img {
          object-fit: cover;
          width: 100% !important;
          height: 100% !important;
          top: 0 !important;
          bottom: 0 !important;
        }
      }
    }
  }
}

.slick-dots {
  z-index: 55 !important;
  bottom: -50px;

  li {
    button {
      margin: 0 auto;
      background: $dots-color;
    }

    &.slick-active {
      button {
        background: $dots-active-color;
      }
    }
  }
}

//main_visual swiper
.main_visual {
  .main_visual_swiper {

    .swiper-slide {
      position: relative;

      a {
        color: white;
      }

      .ofi {
        padding-top: 700px; //任意の値で設定可能
        //padding-top: calc(100vh - #{$header_height}px);
      }

      .text_wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        background: rgba(black, 0.2);
        padding: 1em;

        h3 {
          color: white;
          padding: 0;
          margin: 0 0 .5em;

          &:after {
            content: none;
          }
        }
      }
    }

  }

  //IE対応のためボタンのCSSが必須
  .swiper-button-prev, .swiper-button-next {
    background: $color_primary;
    color: white;
    width: 60px;
    height: 60px;
    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
    }

    &:after {
      font-size: 16px;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .swiper-button-prev {
    @media (max-width: 767px) {
      left: 0;
    }
  }

  .swiper-button-next {
    @media (max-width: 767px) {
      right: 0;
    }
  }

  .swiper-pagination {
    position: static;
    margin: 1em 0;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: $color_primary;
      margin: 0 5px;
      outline-color: transparent;
    }
  }
}
