@charset "utf-8";

header {
  .global_nav {
    > ul {
      > li {
        // 1階層目親メニュー
        &.menu-item-has-children {
          @if $navi_event_type == 'hover' {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          > a {
            position: relative;
            @if $navi_event_type == 'hover' {
              @media (max-width: ($header_break_point - 1)+px) {
                width: calc(100% - 49px); //開閉ボタン用の幅調整
              }
            }

            &:after {
              content: "";
              display: block;
              position: absolute;
              top: auto;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto;
              width: 7px;
              height: 7px;
              border-top: 2px solid $color_primary;
              border-right: 2px solid $color_primary;
              transform: translate(0, -50%) rotate(135deg);
              transition: .2s;
              @if $navi_event_type == 'hover' {
                @media (max-width: ($header_break_point - 1)+px) {
                  content: none;
                }
              }
            }
          }

          &.menu_under_open {
            > a {
              &:after {
                transform: translate(0, 0) rotate(-45deg);
              }
            }
          }
        }

        // 2階層目サブメニュー
        > .sub-menu {
          @include listclear();
          @if $navi_event_type == 'click' {
            display: none;
          }
          @if $navi_event_type == 'hover' {
            @media (min-width: $header_break_point+px) {
              opacity: 0;
              visibility: hidden;
            }
          }
          @media (max-width: ($header_break_point - 1)+px) {
            width: 100%;
            display: none;
          }

          > li {
            border-bottom: 1px solid $color_gnav_border_sp;

            &:last-child {
              border-bottom: none;
            }

            > a {
              background: lighten($color_gnav_bg, 10%);
              padding-left: 2em;
              position: relative;
            }

            &.menu-item-has-children {
              position: relative;
              display: flex;
              flex-wrap: wrap;

              > a {
                position: relative;
                @if $navi_event_type == 'click' {
                  width: 100%;
                }
                @if $navi_event_type == 'hover' {
                  @media (max-width: ($header_break_point - 1)+px) {
                    width: calc(100% - 49px);
                  }
                }

                &:after {
                  @media (min-width: $header_break_point+px) {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 1em;
                    bottom: 0;
                    margin: auto;
                    width: 7px;
                    height: 7px;
                    border-top: 1px solid #fff;
                    border-right: 1px solid #fff;
                    transform: rotate(45deg);
                    transition: .2s;
                  }
                }

                @media (max-width: ($header_break_point - 1)+px) {
                  &:after {
                    @if $navi_event_type == 'click' {
                      content: "";
                      display: block;
                      position: absolute;
                      top: 0;
                      right: 1em;
                      //left: 0;
                      bottom: 0;
                      margin: auto;
                      width: 7px;
                      height: 7px;
                      border-top: 2px solid $color_navlink_sp;
                      border-right: 2px solid $color_navlink_sp;
                      transform: translate(0, -50%) rotate(135deg);
                      transition: .2s;
                    }
                    @if $navi_event_type == 'hover' {
                      content: none;
                    }
                  }

                }
              }

              @media (max-width: ($header_break_point - 1)+px) {
                &.menu_under_open {
                  > a {
                    &:after {
                      transform: translate(0, 0) rotate(-45deg);
                    }
                  }
                }
              }

              //3階層目サブメニュー
              > .sub-menu {
                @include listclear();
                @if $navi_event_type == 'click' {
                  display: none;
                }
                @if $navi_event_type == 'hover' {
                  @media (min-width: $header_break_point+px) {
                    opacity: 0;
                    visibility: hidden;
                    transition: .3s;
                  }
                  @media (max-width: ($header_break_point - 1)+px) {
                    display: none;
                  }
                  //@media (max-width: ($header_break_point - 1)+px) {
                  //  opacity: 1;
                  //  visibility: visible;
                  //  display: block;
                  //}
                }
                //background: lighten($color_gnav_bg, 10%);
                @media (min-width: $header_break_point+px) {
                  position: absolute;
                  top: 0;
                  left: 100%;
                  min-width: 200px;
                }
                @media (max-width: ($header_break_point - 1)+px) {
                  width: 100%;
                  display: none;
                }

                li {
                  &:not(:last-child) {
                    border-bottom: #fff solid 1px;
                    @media (max-width: ($header_break_point - 1)+px) {
                      border-bottom: $color_gnav_border_sp solid 1px;
                    }
                  }

                  &:last-child {
                    border-bottom: none;
                  }

                  a {
                    background: lighten($color_gnav_bg, 15%);
                    @media (max-width: ($header_break_point - 1)+px) {
                      padding-left: 2.5em;
                    }
                  }
                }
              }

              &:hover, &.menu_under_open {
                > .sub-menu {
                  @if $navi_event_type == 'click' {
                    display: none;
                  }
                  @if $navi_event_type == 'hover' {
                    @media (min-width: $header_break_point+px) {
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//サブメニュー開閉用ボタン（hover専用 click時無効）
@if $navi_event_type == 'hover' {
  .child-menu-button {
    position: relative;
    width: 49px;
    min-height: 49px;
    background: #000;
    border: 0;
    outline: 0;
    border-radius: 0;
    cursor: pointer;
    appearance: none;
    @media (min-width: $header_break_point+px) {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      background: #fff;
      transition: .2s ease;
    }

    &:before {
      width: 15px;
      height: 1px;
    }

    &:after {
      width: 1px;
      height: 15px;

    }

    &.is-open {
      &:before {
        opacity: 0;
        transform: rotate(90deg);
      }

      &:after {
        transform: rotate(90deg);
      }

      + .sub-menu {
        opacity: 1 !important;
        visibility: visible !important;
        display: none;
      }
    }
  }
}

//要リファクタリング
@media (min-width: $header_break_point+px) {
  header {
    .global_nav {
      > ul {
        > li {
          &.menu-item-has-children {
            position: relative;
            cursor: pointer;

            > a {
              // padding-right: 2em;
              position: relative;

              &:after {
                // border-color: $color_navlink_pc;
                width: 6px;
                height: 6px;
              }

              &:after {
                transform: translate(0, -50%) rotate(135deg);
              }

              &.menu_under_open {
                &:after {
                  transform: translate(0) rotate(-45deg);
                }
              }
            }

            > .sub-menu {
              position: absolute;
              right: 0;
              margin: auto;
              top: 100%;
              min-width: 200px;
              letter-spacing: -.40em;
              z-index: 999;

              > li {
                letter-spacing: normal;
                display: block;
                vertical-align: middle;
                width: 100%;
                text-align: center;
                border-bottom: 0;
                padding: 0;

                &:not(:last-child) {
                  border-bottom: white solid 1px;
                }

                a {
                  display: block;
                  //background: transparent;
                  background: $color_gnav_bg;
                  padding: .5em;
                  color: $color_white;

                  &:before {
                    content: none;
                  }
                }
              }
            }

            @if $navi_event_type == 'hover' {
              @media (max-width: ($header_break_point - 1)+px) {
                opacity: 1;
                visibility: visible;
              }
              &:hover {
                .sub-menu {
                  opacity: 1;
                  visibility: visible;
                }

                > a {
                  &:after {
                    transform: translate(0) rotate(-45deg);
                  }
                }

                > ul {
                  opacity: 1;
                  height: auto;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
}

