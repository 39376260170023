@charset "utf-8";
//*****************下層*****************//


.box-border {
  border: solid 2px $color_primary;
  padding: 1.5em;
  background: #fff;
}

section.highlight {
  padding-left: 0;
  padding-right: 0;
  &:last-child {
    margin-bottom: 0;
  }
}

.sp_text {
  display: inline;
  padding: 0;
  height: 0;
  @media screen and ( min-width : 768px ) {
    display: none;
  }
}

.flow-block {
  padding: 0 0 3em;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    bottom: 15px;
    margin: 0 auto -30px;
    border: 30px solid transparent;
    border-top: 20px solid $color_primary;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}

.anchro_list {
  list-style: none;
  padding: 1.5em;
  border: double 3px #ccc;
  li {
    a {
      color: $color_secondary;
      text-decoration: none;
      padding-left: 30px;
      position: relative;
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent $color_secondary;
        position: absolute;
        top: .2em;
        left: 0;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and  ( min-width : 768px ) {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 48%;
      &:nth-child(odd) {
        margin-right: 4%;
      }
      &:not(:nth-child(-n + 2)) {
        margin-top: 10px;
      }
    }
  }
}