@charset "utf-8";
.article_detail {
  margin-bottom: 4%;
  @include fontsize(15);

  h2 {
    text-align: left;
    margin: 0 0 1em;
    padding: 0 0 .5em !important;
    @include fontsize(24);
    border-bottom: $color_primary solid 2px;
    @media (max-width: 767px) {
      @include fontsize(19);
    }

    &:after {
      content: none;
    }

    a {
      &:hover {
        text-decoration: none;
        color: $color_primary;
      }
    }
  }

  .span_12 {
    margin: 2% 0;
  }

  .post_header {
    .time {
      &:before {
        content: '\f073';
        font-family: "Font Awesome 5 Free";
        margin-right: .5em;
      }
    }

    .category_badge {
      background: $color_primary;
      color: white;
      @include fontsize(14);
      margin-left: 1em;
      display: inline-block;

      a {
        display: block;
        padding: .2em 1em;
        color: white;
        text-decoration: none;
      }
    }
  }
}

//ページャー
.pager {
  overflow: hidden;
  margin-bottom: 5%;

  .pager_prev {
    float: left;

    a {
      display: block;
      position: relative;
      padding-left: 20px;
      text-decoration: none;
      &:after {
        content: '';
        width: 8px;
        height: 8px;
        border: 0;
        border-top: solid 2px $color_primary;
        border-left: solid 2px $color_primary;
        transform: rotate(
            -45deg);
        position: absolute;
        top: 50%;
        left: 4px;
        margin-top: -6px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .pager_next {
    float: right;

    a {
      display: block;
      position: relative;
      padding-right: 20px;
      text-decoration: none;

      &:after {
        content: '';
        width: 8px;
        height: 8px;
        border: 0;
        border-top: solid 2px $color_primary;
        border-right: solid 2px $color_primary;
        transform: rotate(
            45deg);
        position: absolute;
        top: 50%;
        right: 4px;
        margin-top: -6px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

