@charset "utf-8";
// ヘッダ検索パーツ
@if ($header_search == true) {
  .nav_search {
    position: relative;
    @media (max-width: ($header_break_point - 1)+px) {
      border-bottom: none !important;
    }

    a {
      padding: 0 .8em !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      @media (max-width: ($header_break_point - 1)+px) {
        display: none !important;
      }

      svg {
        width: 20px;
        height: 20px;
        fill: $color_text;
      }
    }
  }

  .nav_search_box {
    position: absolute;
    right: 0;
    top: 130%;
    z-index: 999;
    background: $color_primary;
    padding: 3px;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    @media (max-width: ($header_break_point - 1)+px) {
      visibility: visible;
      opacity: 1;
      position: static;
      padding: 1em 1em;
    }

    form {
      display: flex;
      width: 260px;
      @media (max-width: ($header_break_point - 1)+px) {
        width: 100%;
      }

      input {
        appearance: none;
        border: none;
        border-radius: 0;
        outline: none;
        flex-grow: 1;
        max-width: calc(100% - 40px);
        @media (max-width: 767px) {
          @include fontsize(16);
        }
      }

      input[type="text"] {
        padding: .5em .5em;
      }

      label {
        width: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: ($header_break_point - 1)+px) {
          width: 50px;
          background: darken($color_primary, 10%);
        }

        svg {
          width: 24px;
          height: 24px;
          fill: $color_text_inverse;
        }
      }

      input[type="submit"] {
        display: none;
      }
    }

    &.is-open {
      visibility: visible;
      opacity: 1;
    }
  }
} @else {
  .nav_search {
    display: none;
  }

  .nav_search_box {
    display: none;
  }
}

//サーチページ
.p-search {
  .article_list {
    padding: 0;
    border-bottom: $color_highlight_bg solid 1px!important;
    border-top: none;

    &:first-of-type {
      border-top: $color_highlight_bg solid 1px;
    }

    .col {
      margin: 0;
    }

    .search_item {
      display: block;
      text-decoration: none;
      color: currentColor;
      padding: 1em;

      &:hover {
        opacity: 0.8;
        background: rgba($color_highlight_bg, 0.03);
      }

      h2 {
        margin: 0 0 0 !important;
        padding-bottom: 0 !important;
        text-align: left;

        &:after {
          content: none;
        }

        ~ p {
          margin-top: .6em;
        }
      }
    }
  }
}