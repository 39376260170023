@charset "UTF-8";
@import url("https://use.fontawesome.com/releases/v5.6.1/css/all.css");
/*!
  12 COLUMN : RESPONSIVE GRID SYSTEM
  DEVELOPER : DENIS LEBLANC
  URL : http://responsive.gs
  VERSION : 3.0
  LICENSE : GPL & MIT
*/
*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  max-width: 100%;
  margin: 0 auto;
}

.row {
  padding-bottom: 0;
}

.col {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .span_1 {
    width: 8.33333333333%;
  }
  .span_2 {
    width: 16.6666666667%;
  }
  .span_3 {
    width: 25%;
  }
  .span_4 {
    width: 33.3333333333%;
  }
  .span_5 {
    width: 41.6666666667%;
  }
  .span_6 {
    width: 50%;
  }
  .span_7 {
    width: 58.3333333333%;
  }
  .span_8 {
    width: 66.6666666667%;
  }
  .span_9 {
    width: 75%;
  }
  .span_10 {
    width: 83.3333333333%;
  }
  .span_11 {
    width: 91.6666666667%;
  }
  .span_12 {
    width: 100%;
  }
  .gutters .row {
    column-gap: 2%;
    row-gap: 20px;
  }
  .gutters .span_1 {
    width: 6.5%;
  }
  .gutters .span_2 {
    width: 15%;
  }
  .gutters .span_3 {
    width: 23.5%;
  }
  .gutters .span_4 {
    width: 32%;
  }
  .gutters .span_5 {
    width: 40.5%;
  }
  .gutters .span_6 {
    width: 49%;
  }
  .gutters .span_7 {
    width: 57.5%;
  }
  .gutters .span_8 {
    width: 66%;
  }
  .gutters .span_9 {
    width: 74.5%;
  }
  .gutters .span_10 {
    width: 83%;
  }
  .gutters .span_11 {
    width: 91.5%;
  }
  .gutters .span_12 {
    width: 100%;
  }
}

/*!
 12 COLUMN : RESPONSIVE GRID SYSTEM Fix Ver. 1.2.1
*/
.container {
  padding: 0 1%;
}

.container img {
  max-width: 100%;
  height: auto;
}

* + .container {
  margin-top: 2em;
}

.row:not(:last-child) {
  margin-bottom: 4%;
}

.col:not(:last-child) {
  margin-bottom: 3%;
}

@media (min-width: 768px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0 10px;
  }
  * + .container {
    margin-top: 40px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .row.reverse {
    flex-flow: row-reverse;
  }
  .col:not(:last-child) {
    margin-bottom: 0;
  }
  .col.offset_1 {
    margin-left: 8.33333333333%;
  }
  .col.offset_2 {
    margin-left: 16.6666666667%;
  }
  .col.offset_3 {
    margin-left: 25%;
  }
  .col.offset_4 {
    margin-left: 33.3333333333%;
  }
  .col.offset_5 {
    margin-left: 41.6666666667%;
  }
  .col.offset_6 {
    margin-left: 50%;
  }
  .col.offset_7 {
    margin-left: 58.3333333333%;
  }
  .col.offset_8 {
    margin-left: 66.6666666667%;
  }
  .col.offset_9 {
    margin-left: 75%;
  }
  .col.offset_10 {
    margin-left: 83.3333333333%;
  }
  .col.offset_11 {
    margin-left: 91.6666666667%;
  }
  .col.offset_12 {
    margin-left: 100%;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .d-flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .reverse {
    flex-direction: row-reverse;
  }
  .align-items-start {
    align-items: flex-start;
  }
  .align-items-center {
    align-items: center;
  }
  .align-items-end {
    align-items: flex-end;
  }
  .justify-content-start {
    justify-content: flex-start;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .justify-content-end {
    justify-content: flex-end;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .row.col_5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

.grid_16 .span_1 {
  width: 6.25%;
}

.grid_16 .span_2 {
  width: 12.5%;
}

.grid_16 .span_3 {
  width: 18.75%;
}

.grid_16 .span_4 {
  width: 25%;
}

.grid_16 .span_5 {
  width: 31.25%;
}

.grid_16 .span_6 {
  width: 37.5%;
}

.grid_16 .span_7 {
  width: 43.75%;
}

.grid_16 .span_8 {
  width: 50%;
}

.grid_16 .span_9 {
  width: 56.25%;
}

.grid_16 .span_10 {
  width: 62.5%;
}

.grid_16 .span_11 {
  width: 68.75%;
}

.grid_16 .span_12 {
  width: 75%;
}

.grid_16 .span_13 {
  width: 81.25%;
}

.grid_16 .span_14 {
  width: 87.5%;
}

.grid_16 .span_15 {
  width: 93.75%;
}

.grid_16 .span_16 {
  width: 100%;
}

.grid_16.gutters .span_1 {
  width: 4.375%;
}

.grid_16.gutters .span_2 {
  width: 10.75%;
}

.grid_16.gutters .span_3 {
  width: 17.125%;
}

.grid_16.gutters .span_4 {
  width: 23.5%;
}

.grid_16.gutters .span_5 {
  width: 29.875%;
}

.grid_16.gutters .span_6 {
  width: 36.25%;
}

.grid_16.gutters .span_7 {
  width: 42.625%;
}

.grid_16.gutters .span_8 {
  width: 49%;
}

.grid_16.gutters .span_9 {
  width: 55.375%;
}

.grid_16.gutters .span_10 {
  width: 61.75%;
}

.grid_16.gutters .span_11 {
  width: 68.125%;
}

.grid_16.gutters .span_12 {
  width: 74.5%;
}

.grid_16.gutters .span_13 {
  width: 80.875%;
}

.grid_16.gutters .span_14 {
  width: 87.25%;
}

.grid_16.gutters .span_15 {
  width: 93.625%;
}

.grid_16.gutters .span_16 {
  width: 100%;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html.scrollPrevent {
  overflow: hidden;
  height: 100%;
}

body {
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, Arial, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #333;
  height: auto !important;
  overflow: hidden;
  letter-spacing: 0.05em;
}

@media (max-width: 767px) {
  body {
    font-size: 1.6rem;
  }
}

main {
  overflow: hidden;
}

figure {
  margin: 0;
  text-align: center;
}

figure img {
  max-width: 100%;
  height: auto;
}

figure figcaption {
  margin-top: 0.5em;
  text-align: left;
}

picture {
  margin: 0;
  text-align: center;
}

picture img {
  max-width: unset;
  height: auto;
}

a {
  color: #bcd63f;
  transition: all .3s;
}

a:hover {
  color: #bcd63f;
  text-decoration: none;
}

.highlight a {
  color: #333;
}

.highlight a:hover {
  color: #bcd63f;
}

a:not([class]) img, a.swipebox img {
  transition: all .3s;
}

a:not([class]):hover img, a.swipebox:hover img {
  opacity: 0.8;
}

img {
  vertical-align: middle;
}

img.circle {
  border-radius: 50%;
}

p {
  margin: 0.5em 0 1em;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ol,
ul {
  margin: 0 0 1em;
  padding: 0 0 0 2.5em;
  line-height: 1.6;
}

ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0.5em 0;
  padding-left: 2em;
}

ol ol:last-child,
ol ul:last-child,
ul ol:last-child,
ul ul:last-child {
  margin-bottom: 0.5em;
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;
}

ul.list-unstyled ol,
ul.list-unstyled ul {
  padding-left: 2em;
}

ul.list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -7px;
  font-size: 0rem;
  margin-bottom: 16px;
}

ul.list-inline > li {
  display: inline;
  padding-left: 7px;
  padding-right: 7px;
  white-space: nowrap;
  font-size: 1.6rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

main .contents_wrapper {
  max-width: 100%;
  padding: 0;
}

main section {
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  main section {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  main section:last-child {
    margin-bottom: 80px;
  }
}

@media (min-width: 768px) {
  p {
    margin: 0.5em 0 1em;
  }
}

@media (max-width: 900px) {
  .gutters .span_3.column_sub,
  .gutters .span_9.column_main {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .gutters .span_3.column_sub .sidebar {
    letter-spacing: -.40em;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-left: 2%;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap:nth-child(2n+1) {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.article_button {
  margin-top: 5%;
}

@media (min-width: 768px) {
  .article_detail:not(.reverse) .col.span_12 + .span_4,
  .article_list:not(.reverse) .col.span_12 + .span_4 {
    margin-left: 0;
  }
}

.sidebar {
  visibility: hidden;
}

.sidebar > .widget_wrap {
  visibility: visible;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 6%;
}

.sidebar ul a {
  color: #333;
  text-decoration: none;
  display: block;
  padding: .5em;
  border-bottom: rgba(188, 214, 63, 0.1) solid 1px;
}

.sidebar ul a:hover {
  color: #bcd63f;
  background: rgba(188, 214, 63, 0.05);
}

.gutters .col.span_12 + .span_12 {
  margin-left: 0;
}

.map_wide #map_canvas,
.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map_wide img {
  max-width: none;
}

.slick-track {
  margin: 0 auto;
}

.col dl p {
  line-height: 1.4;
  margin: 0;
}

.col p {
  line-height: 1.8;
}

.container {
  padding: 0 20px;
}

@media (min-width: 1020px) {
  .container {
    padding: 0;
  }
}

.highlight {
  background: rgba(188, 214, 63, 0.1);
  color: #333;
}

.highlight.round {
  border-radius: 10px;
}

section.highlight, p.highlight, .col.highlight {
  padding: 4%;
}

.js-header_break_point {
  display: none;
  z-index: 1000;
}

:root {
  --header_break_point: 1000;
}

:root {
  --navi_event_type: hover;
}

.column_main .col p + *:not(p), .column_main .col table + *:not(p) {
  margin-top: 40px;
}

.globalHeader {
  background: #fff;
  color: #333;
  font-size: 14px;
}

@media (max-width: 999px) {
  .globalHeader.high {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 999px) {
  .globalHeader {
    background: #fff;
    position: fixed;
    z-index: 999;
    width: 100%;
  }
}

.globalHeader .container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  height: 157px;
  max-width: 100%;
}

.globalHeader .container .globalHeader_top {
  width: 98%;
  margin: 0 auto;
}

@media (max-width: 999px) {
  .globalHeader .container {
    height: 60px;
  }
}

.globalHeader .globalHeader_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.globalHeader .header_logo {
  width: 307px;
  height: 22px;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 999px) {
  .globalHeader .header_logo {
    width: 240px;
    height: auto;
  }
}

@media (max-width: 767px) {
  .globalHeader .header_logo {
    width: 150px;
    height: auto;
  }
}

.globalHeader .header_logo a {
  display: block;
  height: auto;
  line-height: 0;
}

.globalHeader .header_logo a img {
  max-width: 100%;
  height: auto;
}

.global_nav {
  width: auto;
  list-style-position: inside;
}

@media (max-width: 999px) {
  .global_nav {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    height: calc(100dvh - 60px);
    z-index: 56;
    transition: all .3s;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.global_nav > ul {
  list-style: none;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 1000px) {
  .global_nav > ul {
    margin-top: 95px;
  }
}

@media (max-width: 999px) {
  .global_nav > ul {
    display: block;
    background: rgba(188, 214, 63, 0.9);
    width: 80vw;
    max-width: 400px;
    transform: translateX(100%);
    transition: all .3s;
    height: 100%;
    overflow: auto;
  }
}

@media (max-width: 999px) {
  .global_nav > ul li {
    border-bottom: black solid 1px;
  }
  .global_nav > ul li:first-child {
    border-top: black solid 1px;
  }
}

.global_nav > ul li a {
  font-size: 1.6rem;
  color: #333;
  text-decoration: none;
  padding: .5em 1em;
  transition: all .3s;
}

@media screen and (min-width: 768px) {
  .global_nav > ul li a {
    padding: .5em 0.4em;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1300px) {
  .global_nav > ul li a {
    padding: .5em 1.3em;
    font-size: 1.6rem;
  }
}

@media (min-width: 1000px) {
  .global_nav > ul li a {
    position: relative;
  }
}

@media (max-width: 999px) {
  .global_nav > ul li a {
    display: block;
    padding: 1em;
    color: #333;
    position: relative;
  }
  .global_nav > ul li a:after {
    content: '';
    width: 8px;
    height: 8px;
    border: 0;
    border-top: solid 2px #333;
    border-right: solid 2px #333;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -4px;
  }
}

.global_nav > ul li a:hover {
  opacity: 0.8;
}

.global_nav > ul li:first-child a:before {
  content: none;
}

@media (max-width: 999px) {
  .global_nav.open {
    opacity: 1;
    visibility: visible;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
}

@media (max-width: 999px) {
  .global_nav.open > ul {
    transform: translateX(0);
  }
}

/**ハンバーガーメニュー**/
#headerToggle {
  display: none;
}

@media (max-width: 999px) {
  #headerToggle {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    z-index: 99;
    cursor: pointer;
    width: 30px;
    height: 25px;
    transition: all .3s;
    height: 19px;
    transform: translateY(-4px);
  }
  #headerToggle span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #bcd63f;
    transition: all .4s;
  }
  #headerToggle span:nth-of-type(1) {
    top: 0;
  }
  #headerToggle span:nth-of-type(2) {
    top: 11px;
    top: 8px;
  }
  #headerToggle span:nth-of-type(3) {
    bottom: 0;
  }
  #headerToggle:after {
    content: 'MENU';
    position: absolute;
    top: auto;
    bottom: -16px;
    left: -100%;
    right: -100%;
    margin: auto;
    width: 100%;
    font-size: 8px !important;
    letter-spacing: -0.02em;
    color: #bcd63f;
    text-align: center;
    font-weight: bold;
  }
  #headerToggle.active span {
    width: 80%;
    left: 10%;
  }
  #headerToggle.active span:nth-of-type(1) {
    transform: translateY(11px) rotate(-45deg);
    transform: translateY(8px) rotate(-45deg);
  }
  #headerToggle.active span:nth-of-type(2) {
    left: 50%;
    opacity: 0;
  }
  #headerToggle.active span:nth-of-type(3) {
    transform: translateY(-11px) rotate(45deg);
    transform: translateY(-8px) rotate(45deg);
  }
  #headerToggle.active:after {
    content: 'CLOSE';
    letter-spacing: -0.1em;
  }
}

.hdr_info_wrapper {
  display: none;
}

@media (min-width: 1000px) {
  .hdr_info_wrapper {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.hdr_info_wrapper .hdr_info {
  display: flex;
  align-items: center;
  margin-right: 0;
}

.hdr_info_wrapper .hdr_info_group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hdr_info_wrapper .hdr_info_group li {
  margin-left: 30px;
}

.hdr_info_wrapper .hdr_info_group .button, .hdr_info_wrapper .hdr_info_group .inquiry .form_button, .inquiry .hdr_info_wrapper .hdr_info_group .form_button {
  min-width: 220px;
  font-size: 1.4rem;
}

.sp_hdr_info_wrapper {
  display: none;
}

@media (max-width: 999px) {
  .sp_hdr_info_wrapper {
    display: block;
    position: absolute;
    top: 15px;
    right: 70px;
  }
}

.sp_hdr_info_wrapper .sp_hdr_info_group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li {
  margin-left: 10px;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel {
  color: white;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel a {
  color: #bcd63f;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel i {
  font-size: 3.2rem;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail {
  color: white;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail a {
  color: #bcd63f;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail i {
  font-size: 3.2rem;
}

footer {
  position: relative;
  padding: 100px 0 90px;
  font-size: 14px;
}

@media (max-width: 767px) {
  footer {
    padding: 0 0 1em;
  }
}

footer .fNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer .fNav ul li + li {
  border-top: 1px solid rgba(188, 214, 63, 0.3);
}

footer .fNav ul li a {
  display: block;
  padding: 1em 15px;
  text-decoration: none;
}

footer .fNav ul li.menu-item-has-children ul {
  display: block !important;
}

footer .copy_right {
  font-size: 1rem;
}

@media (max-width: 767px) {
  footer ul {
    border-top: 1px solid rgba(188, 214, 63, 0.3);
  }
  footer ul li > ul {
    border-top: 1px solid rgba(188, 214, 63, 0.3);
  }
  footer ul li.menu-item-has-children ul {
    display: block !important;
    height: auto !important;
  }
  footer ul li.menu-item-has-children ul a {
    font-size: 90%;
    padding-left: 1.5em;
  }
  footer ul li.menu-item-has-children ul ul a {
    padding-left: 2.5em;
  }
  footer .col {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 768px) {
  footer .row {
    max-width: 700px;
  }
  footer .fNav {
    margin-bottom: 5%;
  }
  footer .fNav ul {
    width: 100%;
    text-align: left;
    border-bottom: none;
  }
  footer .fNav ul li {
    border: none !important;
  }
  footer .fNav ul li a {
    font-size: 1.6rem;
    padding: .35em 1em .35em 1em !important;
    text-decoration: none;
    position: relative;
  }
  footer .fNav ul li a:hover {
    text-decoration: underline;
  }
  footer .fNav ul li a:before {
    content: '';
    position: absolute;
    display: block;
    margin: auto;
    left: 0;
    right: auto;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #bcd63f;
    border-width: 3.5px 0px 3.5px 4px;
  }
  footer .fNav ul li > ul {
    padding-left: .2em;
  }
  footer .fNav ul li > ul a {
    font-size: 90%;
  }
  footer .fNav ul li > ul a:before {
    width: 5px;
    height: 5px;
    border: none;
    background: #bcd63f;
    border-radius: 50%;
    margin-top: 11px;
  }
  footer .fNav ul li > ul .sub-menu {
    padding-left: 1em;
  }
  footer .fNav ul li > ul .sub-menu a:before {
    width: 5px;
    height: 2px;
    border: none;
    background: #bcd63f;
    border-radius: 0;
    transform: rotate(0);
  }
}

.copy_right {
  background: #3f8a42;
  color: white;
  font-size: 1.5rem;
  margin: 0;
  border-top: 1px solid #fff;
  padding: 2em 0;
}

@media (max-width: 767px) {
  .copy_right {
    padding: 1em 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li.tablet-break:after {
    content: "\A";
    white-space: pre;
  }
  footer nav ul li.tablet-break + li:before {
    display: none;
  }
}

footer {
  background: #3f8a42;
  color: #fff;
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #fff;
}

@media (min-width: 768px) {
  footer .container .row nav ul {
    display: inline-block;
  }
  footer .container .row nav a {
    display: inline-block;
    padding: 0.3em;
  }
}

.main_visual {
  position: relative;
  margin-bottom: 100px;
}

@media (max-width: 999px) {
  .main_visual {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .main_visual {
    margin-bottom: 40px;
  }
}

.main_visual .main_visual_slick, .main_visual .slick-track {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100% !important;
}

.main_visual .slick-track {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.main_visual .slick-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
}

.main_visual .main_visual_slick {
  position: relative;
  margin: 0 auto;
}

.main_visual .main_visual_slick .slick-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.main_visual .main_visual_slick .slick-slide picture, .main_visual .main_visual_slick .slick-slide img {
  position: absolute;
  left: -300%;
  right: -300%;
  margin: auto;
  top: 0;
  width: auto;
  height: 100%;
}

.main_visual .main_visual_copy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  z-index: 53;
}

.main_visual .main_visual_copy > li {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.main_visual .main_visual_copy > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 190%;
  max-width: 1900px;
  max-height: 462px;
  z-index: 52;
}

@media (max-width: 767px) {
  .main_visual .main_visual_copy > li img {
    width: 90%;
  }
}

.main_visual .main_visual_slick {
  padding-top: 72.8%!important;
}

@media (min-width: 1000px) {
  .main_visual .main_visual_slick {
    padding-top: 0 !important;
    height: 728px !important;
  }
}

@media (min-width: 1920px) {
  .main_visual .main_visual_slick {
    padding-top: 37.91667%!important;
  }
}

@media (max-width: 767px) {
  .main_visual .main_visual_slick {
    padding-top: 114.66667%!important;
  }
}

.slick-dots {
  z-index: 55 !important;
  bottom: -50px;
}

.slick-dots li button {
  margin: 0 auto;
  background: #ccc;
}

.slick-dots li.slick-active button {
  background: #bcd63f;
}

.main_visual .main_visual_swiper .swiper-slide {
  position: relative;
}

.main_visual .main_visual_swiper .swiper-slide a {
  color: white;
}

.main_visual .main_visual_swiper .swiper-slide .ofi {
  padding-top: 700px;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 1em;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper h3 {
  color: white;
  padding: 0;
  margin: 0 0 .5em;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper h3:after {
  content: none;
}

.main_visual .swiper-button-prev, .main_visual .swiper-button-next {
  background: #bcd63f;
  color: white;
  width: 60px;
  height: 60px;
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev, .main_visual .swiper-button-next {
    width: 40px;
    height: 40px;
  }
}

.main_visual .swiper-button-prev:after, .main_visual .swiper-button-next:after {
  font-size: 16px;
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev:after, .main_visual .swiper-button-next:after {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev {
    left: 0;
  }
}

@media (max-width: 767px) {
  .main_visual .swiper-button-next {
    right: 0;
  }
}

.main_visual .swiper-pagination {
  position: static;
  margin: 1em 0;
}

.main_visual .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #bcd63f;
  margin: 0 5px;
  outline-color: transparent;
}

h1:not(.header_logo) {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  padding: .7em 5%;
  color: #333;
  background: url("/wp-content/uploads/h1.jpg") no-repeat center/cover;
  margin: 0 0 40px;
}

@media (max-width: 767px) {
  h1:not(.header_logo) {
    margin-bottom: 10px;
  }
}

@media (max-width: 999px) {
  h1:not(.header_logo) {
    margin-top: 60px;
  }
}

h1:not(.header_logo) span {
  display: block;
  font-size: 1.6rem;
  padding-top: .5em;
  color: #4d4d4d;
  font-style: italic;
}

h1:not(.header_logo) a {
  text-decoration: none;
}

h1:not(.header_logo) a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  h1:not(.header_logo) {
    font-size: 2.4rem;
  }
  h1:not(.header_logo) span {
    font-size: 1.4rem;
  }
}

h2 {
  display: block;
  margin: 0 auto 1em;
  position: relative;
  text-align: center;
  border: 0;
  font-weight: bold;
  padding: 0 0 .5em;
  font-size: 3rem;
  color: #333;
}

h2 a {
  text-decoration: none;
  color: #333;
}

h2 a:hover {
  text-decoration: underline;
  color: #333;
}

h2 span {
  display: block;
  font-size: 1.4rem;
  padding-top: 1em;
}

h2:after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  top: auto;
  margin: auto;
  background: #bcd63f;
}

@media (max-width: 767px) {
  h2 {
    font-size: 2.4rem;
  }
  h2:after {
    width: 20px;
  }
}

h3 {
  margin: 0 0 1em;
  padding-bottom: 0.5em;
  color: #333;
  font-size: 2.4rem;
  position: relative;
}

h3 a {
  text-decoration: none;
}

h3 a:hover {
  text-decoration: underline;
}

h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(188, 214, 63, 0.2);
}

@media (max-width: 767px) {
  h3 {
    font-size: 2rem;
  }
}

h4 {
  margin: 0 0 1em;
  padding-left: 1em;
  font-size: 1.8rem;
  position: relative;
  color: #333;
  font-weight: bold;
}

h4 a {
  text-decoration: none;
}

h4 a:hover {
  text-decoration: underline;
}

h4:after {
  content: '';
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 1em;
  margin: auto;
  background: #bcd63f;
}

@media (max-width: 767px) {
  h4 {
    font-size: 1.4rem;
  }
}

.widget_wrap .widget_header {
  font-size: 1.6rem;
  color: #333;
  padding: .5em 0 .5em .5em;
  border-bottom: #bcd63f solid 2px;
  font-weight: bold;
}

.widget_wrap .widget_header a {
  text-decoration: none;
}

.widget_wrap .widget_header a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .widget_wrap .widget_header {
    font-size: 1.4rem;
  }
}

.bread {
  overflow: hidden;
  font-size: 1.2rem;
  margin: 0 auto 60px;
  width: 100%;
  max-width: 1000px;
}

@media (max-width: 767px) {
  .bread {
    margin: 0 auto 40px;
  }
}

.bread ul {
  padding: 1em 0;
  margin: 0 auto;
  float: none;
}

.bread ul li {
  list-style: none;
  position: relative;
  float: left;
  padding-right: 2em;
  margin-right: 1.5em;
}

.bread ul li a {
  text-decoration: none;
  color: #bcd63f;
}

.bread ul li a:hover {
  color: #bcd63f;
  text-decoration: none;
}

.bread ul li:after {
  content: '>';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bread ul li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.bread ul li:last-child:after {
  content: none;
}

.button, .inquiry .form_button {
  display: inline-block;
  max-width: 280px;
  width: 100%;
  margin: 0;
  padding: 1em 1em;
  border: #bcd63f solid 1px;
  border-radius: 0px;
  line-height: 1;
  background: #bcd63f;
  color: #333;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 10px;
  /*
* ボタン矢印
*/
}

.button:hover, .inquiry .form_button:hover {
  background: #b3cf2c;
  color: #333;
  border-color: #b3cf2c;
}

.button.arrow, .inquiry .arrow.form_button {
  position: relative;
  font-size: 1.6rem;
}

.button.arrow:after, .inquiry .arrow.form_button:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
  right: 5%;
  margin: auto;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(-45deg);
  transition: 0.2s;
}

.button.arrow:hover, .inquiry .arrow.form_button:hover {
  border: 1px solid #bcd63f;
}

.button.arrow:hover:after, .inquiry .arrow.form_button:hover:after {
  right: 4%;
}

.highlight .button, .highlight .inquiry .form_button, .inquiry .highlight .form_button {
  color: #333;
}

.highlight .button:hover, .highlight .inquiry .form_button:hover, .inquiry .highlight .form_button:hover {
  color: #333;
}

.button.inverse, .inquiry .inverse.form_button {
  background: none;
  border-color: #bcd63f;
  color: #bcd63f;
}

.button.inverse:after, .inquiry .inverse.form_button:after {
  border-color: #bcd63f;
}

.button.inverse:hover, .inquiry .inverse.form_button:hover {
  background: #bcd63f;
  color: #fff;
}

.button.inverse:hover:after, .inquiry .inverse.form_button:hover:after {
  border-color: #fff;
}

.button.block, .inquiry .block.form_button {
  display: block;
  min-width: 100%;
}

.button.small, .inquiry .small.form_button {
  padding: .6em 1em;
  max-width: unset;
  width: auto;
}

.button.small.arrow, .inquiry .small.arrow.form_button {
  padding-right: 2.5em;
}

.button + .button, .inquiry .form_button + .button, .inquiry .button + .form_button, .inquiry .form_button + .form_button {
  margin-left: 0.5em;
}

@media (max-width: 767px) {
  .button + .button, .inquiry .form_button + .button, .inquiry .button + .form_button, .inquiry .form_button + .form_button {
    margin-left: 0;
    margin-top: 1em;
  }
}

/* お問い合わせフォーム */
.inquiry .h-adr {
  margin: 0;
  padding: 4%;
  border: rgba(188, 214, 63, 0.1) solid 5px;
  border-radius: 10px;
}

.inquiry .table-default {
  border: none;
}

.inquiry .table-default tr:not(:last-of-type) {
  border-bottom: 1px solid #cccccc;
}

.inquiry .table-default th {
  background: none;
  border: none;
  text-align: left;
  padding: 1.4em 50px 1.2em .5em;
  vertical-align: top;
  font-weight: bold;
  position: relative;
}

@media (max-width: 767px) {
  .inquiry .table-default th {
    padding: 1em 0 0;
  }
}

.inquiry .table-default th .required {
  display: inline-block;
  background: #d43f3a;
  color: white;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 1.2rem;
  margin-left: .5em;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .inquiry .table-default th .required {
    position: absolute;
    top: 1.6em;
    right: 0;
  }
}

.inquiry .table-default td {
  background: none;
  border: none;
  padding: 1.2em .5em;
}

@media (max-width: 767px) {
  .inquiry .table-default td {
    padding: .5em 0 1em;
  }
}

.inquiry .input_wrap {
  display: block;
  margin: 0;
  padding: 0 .2em;
}

@media (max-width: 767px) {
  .inquiry .input_wrap {
    margin: 0;
  }
}

.inquiry .input_wrap input, .inquiry .input_wrap textarea {
  margin: 0;
}

.inquiry .input_wrap:last-child {
  margin-right: auto;
}

.inquiry .text-2col {
  display: flex;
}

.inquiry .text-2col .input_wrap {
  flex-basis: 50%;
}

.inquiry input[type="text"],
.inquiry input[type="url"],
.inquiry input[type="email"],
.inquiry input[type="tel"],
.inquiry input[type="date"],
.inquiry input[type="number"],
.inquiry select,
.inquiry textarea {
  width: 100%;
  max-width: 100%;
  padding: 7px 2%;
  color: #333;
  font-size: 1.6rem;
  margin: 0 .2em;
  border: 1px solid #cccccc;
  background: #fafafa;
  border-radius: 3px;
  -webkit-appearance: none;
}

@media (max-width: 767px) {
  .inquiry input[type="text"],
  .inquiry input[type="url"],
  .inquiry input[type="email"],
  .inquiry input[type="tel"],
  .inquiry input[type="date"],
  .inquiry input[type="number"],
  .inquiry select,
  .inquiry textarea {
    margin: .2em 0;
  }
}

.inquiry input[type="number"] {
  width: 65px;
  text-align: center;
}

.inquiry input.p-postal-code {
  width: 200px;
}

.inquiry input.p-region {
  width: 100px;
}

.inquiry input.p-locality {
  width: 150px;
}

.inquiry input.p-street-address {
  width: 100%;
}

.inquiry input.p-extended-address {
  width: 100%;
}

.inquiry .mwform-tel-field {
  display: flex;
  justify-content: space-between;
}

.inquiry .submit {
  margin: 1em 0;
  text-align: center;
}

@media (min-width: 768px) {
  .inquiry input[type="text"],
  .inquiry input[type="url"],
  .inquiry input[type="email"],
  .inquiry input[type="tel"],
  .inquiry input[type="date"],
  .inquiry input[type="text"],
  .inquiry select {
    margin: 0 .2em;
  }
  .inquiry input[type="number"] {
    width: 65px;
  }
  .inquiry input.p-postal-code {
    width: 200px;
  }
  .inquiry input.p-region {
    width: 100px;
  }
  .inquiry input.p-locality {
    width: 150px;
  }
  .inquiry input.p-street-address {
    width: 100%;
  }
  .inquiry input.p-extended-address {
    width: 100%;
  }
  .inquiry textarea {
    width: 100%;
  }
  .inquiry .input_wrap {
    display: block;
    margin: 0 .2em;
  }
  .inquiry .input_wrap input, .inquiry .input_wrap textarea {
    margin: 0;
  }
  .inquiry .input_wrap:last-child {
    margin-right: auto;
  }
  .inquiry .submit {
    margin: 60px 0 0;
  }
  .inquiry .form_button, .inquiry .wpcf7-submit {
    background: #bcd63f;
    color: white;
    border-color: #bcd63f;
  }
  .inquiry .form_button.form_back, .inquiry .wpcf7-submit.form_back {
    background: #ccc;
    border-color: #ccc;
    color: #666;
  }
  .inquiry .form_button.form_back:hover, .inquiry .wpcf7-submit.form_back:hover {
    background: #bfbfbf;
    border-color: #bfbfbf;
    color: #666;
  }
  .inquiry .form_button.form_send, .inquiry .wpcf7-submit.form_send {
    background: #a1ba28;
    border-color: #a1ba28;
    color: #fff;
  }
  .inquiry .form_button.form_send:hover, .inquiry .wpcf7-submit.form_send:hover {
    background: #8fa523;
    border-color: #8fa523;
    color: #fff;
  }
}

.form_button_wrap {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .form_button_wrap {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.form_button_wrap button {
  margin: .5em !important;
}

.privacy_wrap {
  margin-top: 5%;
  border: #ccc solid 1px;
  padding: 1em;
  height: 400px;
  overflow-y: scroll;
}

.mw_wp_form_confirm .input-show {
  display: none !important;
}

.mw_wp_form_input .input-hide {
  display: none !important;
}

.mw_wp_form .error {
  color: #d43f3a !important;
}

header .global_nav > ul > li.menu-item-has-children {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

header .global_nav > ul > li.menu-item-has-children > a {
  position: relative;
}

@media (max-width: 999px) {
  header .global_nav > ul > li.menu-item-has-children > a {
    width: calc(100% - 49px);
  }
}

header .global_nav > ul > li.menu-item-has-children > a:after {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 7px;
  height: 7px;
  border-top: 2px solid #bcd63f;
  border-right: 2px solid #bcd63f;
  transform: translate(0, -50%) rotate(135deg);
  transition: .2s;
}

@media (max-width: 999px) {
  header .global_nav > ul > li.menu-item-has-children > a:after {
    content: none;
  }
}

header .global_nav > ul > li.menu-item-has-children.menu_under_open > a:after {
  transform: translate(0, 0) rotate(-45deg);
}

header .global_nav > ul > li > .sub-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu {
    opacity: 0;
    visibility: hidden;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu {
    width: 100%;
    display: none;
  }
}

header .global_nav > ul > li > .sub-menu > li {
  border-bottom: 1px solid black;
}

header .global_nav > ul > li > .sub-menu > li:last-child {
  border-bottom: none;
}

header .global_nav > ul > li > .sub-menu > li > a {
  background: rgba(203, 223, 105, 0.9);
  padding-left: 2em;
  position: relative;
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > a {
  position: relative;
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > a {
    width: calc(100% - 49px);
  }
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > a:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 1em;
    bottom: 0;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);
    transition: .2s;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > a:after {
    content: none;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children.menu_under_open > a:after {
    transform: translate(0, 0) rotate(-45deg);
  }
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
    display: none;
  }
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 200px;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
    width: 100%;
    display: none;
  }
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li:not(:last-child) {
  border-bottom: #fff solid 1px;
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li:not(:last-child) {
    border-bottom: black solid 1px;
  }
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li:last-child {
  border-bottom: none;
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li a {
  background: rgba(210, 227, 126, 0.9);
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li a {
    padding-left: 2.5em;
  }
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children:hover > .sub-menu, header .global_nav > ul > li > .sub-menu > li.menu-item-has-children.menu_under_open > .sub-menu {
    opacity: 1;
    visibility: visible;
  }
}

.child-menu-button {
  position: relative;
  width: 49px;
  min-height: 49px;
  background: #000;
  border: 0;
  outline: 0;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
}

@media (min-width: 1000px) {
  .child-menu-button {
    display: none;
  }
}

.child-menu-button:before, .child-menu-button:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  background: #fff;
  transition: .2s ease;
}

.child-menu-button:before {
  width: 15px;
  height: 1px;
}

.child-menu-button:after {
  width: 1px;
  height: 15px;
}

.child-menu-button.is-open:before {
  opacity: 0;
  transform: rotate(90deg);
}

.child-menu-button.is-open:after {
  transform: rotate(90deg);
}

.child-menu-button.is-open + .sub-menu {
  opacity: 1 !important;
  visibility: visible !important;
  display: none;
}

@media (min-width: 1000px) {
  header .global_nav > ul > li.menu-item-has-children {
    position: relative;
    cursor: pointer;
  }
  header .global_nav > ul > li.menu-item-has-children > a {
    position: relative;
  }
  header .global_nav > ul > li.menu-item-has-children > a:after {
    width: 6px;
    height: 6px;
  }
  header .global_nav > ul > li.menu-item-has-children > a:after {
    transform: translate(0, -50%) rotate(135deg);
  }
  header .global_nav > ul > li.menu-item-has-children > a.menu_under_open:after {
    transform: translate(0) rotate(-45deg);
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu {
    position: absolute;
    right: 0;
    margin: auto;
    top: 100%;
    min-width: 200px;
    letter-spacing: -.40em;
    z-index: 999;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li {
    letter-spacing: normal;
    display: block;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    border-bottom: 0;
    padding: 0;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li:not(:last-child) {
    border-bottom: white solid 1px;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li a {
    display: block;
    background: rgba(188, 214, 63, 0.9);
    padding: .5em;
    color: #fff;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li a:before {
    content: none;
  }
}

@media (min-width: 1000px) and (max-width: 999px) {
  header .global_nav > ul > li.menu-item-has-children {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 1000px) {
  header .global_nav > ul > li.menu-item-has-children:hover .sub-menu {
    opacity: 1;
    visibility: visible;
  }
  header .global_nav > ul > li.menu-item-has-children:hover > a:after {
    transform: translate(0) rotate(-45deg);
  }
  header .global_nav > ul > li.menu-item-has-children:hover > ul {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
}

.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before {
  background: #bcd63f;
}

.movie {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
}

.movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.movie.ar16to9 {
  padding-top: 56.25%;
}

.movie.ar4to3 {
  padding-top: 75%;
}

@media (max-width: 767px) {
  .news .heading {
    display: flex;
    justify-content: space-between;
  }
  .news .heading h2 {
    text-align: left;
    margin: 0 0 1em;
  }
  .news .heading h2:after {
    right: auto;
  }
}

.news dl {
  margin: 0;
  overflow-y: auto;
  max-height: 300px;
}

.news dl dt {
  margin: 0.5em 0 0;
  padding: 0 1em;
  font-weight: normal;
}

.news dl dd {
  margin: 0.5em 0;
  padding: 0 1em 0.5em;
  border-bottom: #ccc solid 1px;
  position: relative;
}

.news dl dd a {
  text-decoration: none;
}

.news dl dd a:hover {
  text-decoration: underline;
}

.news dl .badge_category {
  display: inline-block;
  background: #bcd63f;
  color: white;
  padding: .2em 1em;
  margin-left: 1em;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .news > div {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  .news > div .heading {
    width: 23.5%;
  }
  .news h2 {
    margin: 0;
    padding: 0;
  }
  .news h2:after {
    display: none;
  }
  .news .content {
    overflow: hidden;
    width: 76.5%;
    padding-left: 2%;
  }
  .news dl {
    overflow-y: auto;
    max-height: 200px;
  }
  .news dl dt {
    margin: 0;
    padding: 1em 0 .5em 0;
  }
  .news dl dd {
    margin: 0;
    padding: .5em 0 1em 0;
  }
}

.pagetop {
  position: fixed;
  bottom: 20px;
  right: 5%;
  z-index: 98;
}

.pagetop a {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  background: url("/wp-content/uploads/pagetop.png") no-repeat center/cover;
}

@media (min-width: 768px) {
  .pagetop a {
    width: 86px;
    height: 86px;
  }
}

footer.high .pagetop {
  position: absolute;
  bottom: auto;
  right: 5%;
  top: 0;
  margin: 0;
  transform: translateY(-50%);
}

.p-qa .question {
  font-size: 1.6rem;
  border: none;
  position: relative;
  margin: 0 0 0;
  padding: 1em 50px 1em 1em;
  background-color: rgba(188, 214, 63, 0.1);
  font-weight: normal;
}

.p-qa .question:after {
  content: none;
}

.p-qa .question:before {
  content: none;
}

.p-qa .question.open:after {
  transform: rotate(180deg);
}

.p-qa .question:not(:first-child) {
  margin-top: 1em !important;
}

.p-qa .answer {
  padding: 1em 0 0 1em;
}

.p-qa.qa_active .question {
  cursor: pointer;
}

.p-qa.qa_active .question:hover {
  opacity: 0.8;
}

.p-qa.qa_active .question:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 1em;
  left: auto;
  top: 50%;
  bottom: auto;
  margin-top: -12px;
  background: url(../../../../../wp-content/uploads/arrow_down.svg) center center no-repeat;
  background-size: 24px;
  transition: .5s;
  border: none;
}

.p-qa.qa_active .answer {
  display: none;
}

.highlight .qa .question {
  background: rgba(195, 218, 84, 0.1);
}

.highlight .qa .question:hover {
  background: rgba(191, 216, 71, 0.1);
}

.highlight .qa .question.open:hover {
  background: rgba(195, 218, 84, 0.1);
}

.nav_search {
  display: none;
}

.nav_search_box {
  display: none;
}

.p-search .article_list {
  padding: 0;
  border-bottom: rgba(188, 214, 63, 0.1) solid 1px !important;
  border-top: none;
}

.p-search .article_list:first-of-type {
  border-top: rgba(188, 214, 63, 0.1) solid 1px;
}

.p-search .article_list .col {
  margin: 0;
}

.p-search .article_list .search_item {
  display: block;
  text-decoration: none;
  color: currentColor;
  padding: 1em;
}

.p-search .article_list .search_item:hover {
  opacity: 0.8;
  background: rgba(188, 214, 63, 0.03);
}

.p-search .article_list .search_item h2 {
  margin: 0 0 0 !important;
  padding-bottom: 0 !important;
  text-align: left;
}

.p-search .article_list .search_item h2:after {
  content: none;
}

.p-search .article_list .search_item h2 ~ p {
  margin-top: .6em;
}

.shopinfo figure, .shopinfo picture {
  max-width: 80%;
  margin: 0 auto;
}

.shopinfo address {
  text-align: center;
  font-size: 2rem;
  font-style: normal;
}

.shopinfo dl {
  margin: 2em 0;
  line-height: 1.4;
}

.shopinfo dl:first-child {
  margin-top: 0;
}

.shopinfo dl dt {
  margin: 0 0 0.2em 0;
  font-weight: normal;
}

.shopinfo dl dt:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  margin-right: 0.3em;
  vertical-align: middle;
  background: #bcd63f;
}

.shopinfo dl dd {
  margin: 0 0 0.5em 1em;
  padding: 0;
}

.map_container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.map_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map_container + p {
  margin-top: 2em;
}

.map_wide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.scroll .googlemap {
  pointer-events: none;
}

@media (min-width: 768px) {
  .shopinfo figure, .shopinfo picture {
    max-width: 100%;
  }
  .shopinfo address {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2.6rem;
    line-height: 1;
  }
  .shopinfo dl.dl-horizontal {
    margin: 10px 0;
  }
  .shopinfo dl.dl-horizontal dd {
    margin-bottom: 0.3em;
  }
  .shopinfo .span12 .map_container {
    padding-top: 50%;
  }
  .map_wide {
    padding-top: 420px;
  }
}

.p-sitemap {
  border-top: rgba(51, 51, 51, 0.3) solid 1px;
}

.p-sitemap ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-sitemap ul li a {
  display: block;
  text-decoration: none;
  position: relative;
  padding: .6em 1em .6em 1.5em;
  border-bottom: rgba(51, 51, 51, 0.3) solid 1px;
  font-size: 1.8rem;
}

.p-sitemap ul li a:before {
  content: '';
  width: 6px;
  height: 6px;
  border: 0;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: .5em;
  margin: auto;
}

.p-sitemap ul li a:hover {
  background: rgba(188, 214, 63, 0.1);
}

.p-sitemap ul.children {
  margin: 0;
}

.p-sitemap ul.children li a {
  padding-left: 3em;
  font-size: 1.5rem;
}

.p-sitemap ul.children li a:before {
  left: 2em;
}

.sns_icons {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0rem;
  margin: auto;
}

.sns_icons > li {
  display: inline;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-size: 1.6rem;
}

.sns_icons li {
  display: inline-block;
}

.sns_icons li:not(:last-child) {
  margin-right: 10px;
}

.sns_icons a {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: contain;
}

.sns_icons a.icon_facebook {
  background-image: url(../../../../../wp-content/uploads/icon_facebook.png);
}

.sns_icons a.icon_twitter {
  background-image: url(../../../../../wp-content/uploads/icon_twitter.png);
}

.sns_icons a.icon_line {
  background-image: url(../../../../../wp-content/uploads/icon_line.png);
}

.sns_icons a.icon_google {
  background-image: url(../../../../../wp-content/uploads/icon_google.png);
}

.sns_icons a.icon_instagram {
  background-image: url(../../../../../wp-content/uploads/icon_instagram.png);
}

footer .sns_icons {
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .sns_icons {
    margin: 0;
  }
  .sns_icons.center {
    margin: auto;
  }
  .sns_icons li:not(:last-child) {
    margin-right: 20px;
  }
  .sns_icons a {
    width: 68px;
    height: 68px;
  }
  footer .sns_icons {
    margin-top: 30px;
  }
}

@use "sass:math";
@media (max-width: 767px) {
  .sp_image_cols {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 20px;
  }
  .sp_image_cols.sp_col2 .col {
    width: 49%;
  }
  .sp_image_cols.sp_col3 {
    display: flex;
    flex-wrap: wrap;
  }
  .sp_image_cols.sp_col3 .col {
    width: 33.3333333333%;
    padding: 0 1%;
  }
}

table {
  margin: 0 auto;
}

table td,
table th {
  padding: 0.7em 1em;
}

@media (max-width: 767px) {
  table td,
  table th {
    padding: 0.7em .3em;
  }
}

table th {
  text-align: center;
  font-weight: normal;
}

.table-default {
  width: 100%;
  table-layout: fixed;
}

.table-default thead th, .table-default thead td {
  background: rgba(188, 214, 63, 0.15);
}

.table-default td,
.table-default th {
  border: 1px solid #ccc;
}

.table-default th {
  background: rgba(204, 204, 204, 0.2);
  color: #333;
}

.table-default td {
  background: transparent;
}

.table-default.table-mini td,
.table-default.table-mini th {
  padding: .5em 1em;
}

@media (max-width: 767px) {
  .table-default.table-mini td,
  .table-default.table-mini th {
    padding: .5em .3em;
  }
}

.table-menu {
  width: 100%;
}

.table-menu caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 2rem;
}

.table-menu td,
.table-menu th {
  padding: 0.7em 1em;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

@media (max-width: 767px) {
  .table-menu td,
  .table-menu th {
    padding: 0.7em .3em;
  }
}

.table-menu tbody th {
  text-align: left;
}

.table-menu td:last-child {
  text-align: right;
  white-space: nowrap;
}

.table-schedule {
  width: 100%;
}

.table-schedule caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 2rem;
}

.table-schedule td,
.table-schedule th {
  border: 1px solid #ccc;
  padding: 0.7em 1em;
  text-align: center;
}

@media (max-width: 767px) {
  .table-schedule td,
  .table-schedule th {
    padding: 0.7em 0.3em;
  }
}

.table-schedule thead th {
  background: rgba(204, 204, 204, 0.2);
}

.table-schedule tbody th {
  background: rgba(204, 204, 204, 0.2);
}

.table-schedule td {
  background: transparent;
}

@media (max-width: 767px) {
  .responsive-stack {
    display: block;
  }
  .responsive-stack thead {
    display: none;
  }
  .responsive-stack tbody,
  .responsive-stack td,
  .responsive-stack th,
  .responsive-stack tr {
    display: block;
  }
  .responsive-stack td,
  .responsive-stack th {
    width: 100% !important;
  }
  .responsive-stack tr:not(:last-child) td,
  .responsive-stack tr:not(:last-child) th {
    border-bottom: 0;
  }
  .responsive-stack tr:last-child :not(:last-child) {
    border-bottom: 0;
  }
}

@media (max-width: 767px) {
  .responsive-scroll-container {
    display: block;
    width: 100%;
    min-height: 0.01%;
    overflow-x: scroll;
  }
  .responsive-scroll-container .responsive-scroll-inner {
    width: 798px;
    padding: 0 15px 15px;
  }
  .responsive-scroll-container table {
    width: 768px;
    margin: 0;
  }
}

table.table-default.typeA tr td,
table.table-default.typeA tr th {
  border: 0;
}

table.table-default.typeA tr:nth-child(odd) th {
  background: #bcd63f;
}

table.table-default.typeA tr:nth-child(odd) td {
  background: rgba(188, 214, 63, 0.1);
}

table.table-default.typeA tr:nth-child(even) th {
  background: #cbdf69;
}

table.table-default.typeA tr:nth-child(even) td {
  background: rgba(203, 223, 105, 0.1);
}

table.table-default.typeB {
  border-spacing: 0;
  overflow: hidden;
}

table.table-default.typeB tbody td,
table.table-default.typeB tbody th {
  background: transparent;
  color: #333;
  border: 0;
  border-bottom: 1px solid rgba(204, 204, 204, 0.1);
}

table.table-default.typeB tbody tr {
  position: relative;
}

table.table-default.typeB tbody th {
  color: #bcd63f;
}

table.table-default.typeC th {
  background: rgba(204, 204, 204, 0.2);
  color: #333;
}

table.table-default.typeC td,
table.table-default.typeC th {
  border: 0;
}

table.table-default.typeC td:not(:last-child),
table.table-default.typeC th:not(:last-child) {
  border-bottom: 2px #fff solid;
}

table.table-default.typeC tr {
  position: relative;
}

table.table-default.typeC td {
  transform: translateX(2px);
}

.highlight table.table-default.typeC td,
.highlight table.table-default.typeC th {
  border: 0;
}

.highlight table.table-default.typeC td:not(:last-child),
.highlight table.table-default.typeC th:not(:last-child) {
  border-bottom: 2px rgba(188, 214, 63, 0.1) solid;
}

.article_list {
  padding: .5em;
  border-top: rgba(188, 214, 63, 0.2) solid 1px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .article_list {
    padding: 1em;
  }
}

.article_list .span_12 {
  margin: 2% 0;
}

.article_list:last-of-type {
  border-bottom: rgba(188, 214, 63, 0.2) solid 1px;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .article_list:last-of-type {
    margin-bottom: 40px;
  }
}

.article_list .post_header .time:before {
  content: '\f073';
  font-family: "Font Awesome 5 Free";
  margin-right: .5em;
}

.article_list .post_header .category_badge {
  background: #bcd63f;
  color: white;
  font-size: 1.4rem;
  margin-left: 1em;
  display: inline-block;
}

.article_list .post_header .category_badge a {
  display: block;
  padding: .2em 1em;
  color: white;
  text-decoration: none;
}

.article_list h2 {
  text-align: left;
  margin: 0 0 .5em !important;
  padding: 0;
  font-size: 2.4rem;
}

@media (max-width: 767px) {
  .article_list h2 {
    font-size: 1.9rem;
  }
}

.article_list h2:after {
  content: none;
}

.article_list h2 a:hover {
  text-decoration: none;
  color: #bcd63f;
}

.m-pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .m-pagenation {
    margin-bottom: 50px;
  }
}

.m-pagenation .m-pagenation__prev {
  margin-right: .5em;
}

.m-pagenation .m-pagenation__prev a {
  display: block;
  text-decoration: none;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__prev a:hover {
  background: rgba(188, 214, 63, 0.1);
  border-color: rgba(188, 214, 63, 0.1);
}

.m-pagenation .m-pagenation__next {
  margin-left: .5em;
}

.m-pagenation .m-pagenation__next a {
  display: block;
  text-decoration: none;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__next a:hover {
  background: rgba(188, 214, 63, 0.1);
  border-color: rgba(188, 214, 63, 0.1);
}

.m-pagenation .m-pagenation__body {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.m-pagenation .m-pagenation__body li {
  display: inline-block;
  margin: 0 5px;
}

.m-pagenation .m-pagenation__body li a {
  text-decoration: none;
  display: block;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__body li a:hover {
  background: rgba(188, 214, 63, 0.1);
  border-color: rgba(188, 214, 63, 0.1);
}

.m-pagenation .m-pagenation__body li.current {
  background: #bcd63f;
  color: white;
  border: #bcd63f solid 1px;
  padding: .3em 1em;
}

.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .wp-pagenavi {
    margin-bottom: 50px;
  }
}

.wp-pagenavi a, .wp-pagenavi span {
  display: inline-block;
  margin: 0 5px;
  text-decoration: none;
  padding: .3em 1em;
  border: #ccc solid 1px;
  color: #bcd63f;
}

.wp-pagenavi a.current, .wp-pagenavi span.current {
  background: #bcd63f;
  color: white;
  border: #bcd63f solid 1px;
  padding: .3em 1em;
}

.wp-pagenavi a:hover {
  background: rgba(188, 214, 63, 0.1);
  border-color: rgba(188, 214, 63, 0.1);
}

.article_single01 .article_single01_02 {
  margin-top: 10px;
}

.article_single01 .article_single01_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single01 .article_single01_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single01 .ofi {
  background: rgba(188, 214, 63, 0.1);
}

.article_single01 .more {
  display: block;
  text-align: center;
}

.article_single02 .article_single02_01 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single02 .article_single02_01 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single02 .ofi {
  background: rgba(188, 214, 63, 0.1);
}

.article_single02 .more {
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .article_single03 .article_single03_02 .span_6 {
    width: 45%;
    margin-left: 10%;
  }
  .article_single03 .article_single03_02 .span_6:first-child {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .article_single03 .article_single03_02 .span_12 {
    margin-left: 0;
    margin-top: 20px;
  }
}

.article_single03 .article_single03_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single03 .article_single03_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single03 .case_arrow {
  position: relative;
}

@media (max-width: 767px) {
  .article_single03 .case_arrow {
    margin-bottom: 10%;
  }
}

.article_single03 .case_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 108%;
  margin: auto;
  width: 35px;
  height: 35px;
  border-top: 3px solid #bcd63f;
  border-right: 3px solid #bcd63f;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .article_single03 .case_arrow:after {
    left: 0;
    right: 0;
    top: 105%;
    width: 20px;
    height: 20px;
    transform: translate(0, -50%) rotate(135deg);
    transform-origin: center;
  }
}

.article_single03 .ofi {
  background: rgba(188, 214, 63, 0.1);
}

.article_single03 .more {
  display: block;
  text-align: center;
}

.article_single04 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.article_single04 .article_single04_01_img1 .ofi {
  padding-top: 75%;
}

.article_single04 .article_single04_01_img2 .ofi {
  padding-top: 15%;
}

.article_single04 .article_single04_01_img3 .ofi {
  padding-top: 10.8%;
}

.article_single04 .article_single04_01_img4 .ofi {
  padding-top: 8.4%;
}

.article_single04 .article_single04_01_img5 .ofi {
  padding-top: 6.9%;
}

.article_single04 .article_single04_01_img6 .ofi {
  padding-top: 5.8%;
}

.article_single04 .article_single04_01_nav {
  margin-top: 15px;
}

.article_single04 .article_single04_01_nav .ofi {
  padding-top: 11.1%;
}

.article_single04 .article_single04_01_nav .slick-track {
  width: 100% !important;
  transform: none !important;
  left: 0 !important;
}

.article_single04 .article_single04_01_nav li {
  margin-left: 0.8%;
  width: calc(96% / 6) !important;
}

.article_single04 .article_single04_01_nav li:nth-child(1) {
  margin-left: 0;
}

.article_single04.article_container .article_single04_01_img1 .ofi {
  padding-top: 74.5%;
}

.article_single04.article_container .article_single04_01_img2 .ofi {
  padding-top: 18.7%;
}

.article_single04.article_container .article_single04_01_img3 .ofi {
  padding-top: 14.9%;
}

.article_single04.article_container .article_single04_01_img4 .ofi {
  padding-top: 12.5%;
}

.article_single04.article_container .article_single04_01_img5 .ofi {
  padding-top: 10.7%;
}

.article_single04.article_container .article_single04_01_img6 .ofi {
  padding-top: 9.4%;
}

.article_single04.article_container .article_single04_01_nav {
  margin-top: 15px;
}

.article_single04.article_container .article_single04_01_nav .ofi {
  padding-top: 12%;
}

.article_single04.article_container .article_single04_01_nav .slick-track {
  width: 100% !important;
  transform: none !important;
  left: 0 !important;
}

.article_single04.article_container .article_single04_01_nav li {
  margin-left: 0.8%;
  width: calc(96% / 6) !important;
}

.article_single04.article_container .article_single04_01_nav li:nth-child(1) {
  margin-left: 0;
}

.article_single04 .article_single04_02 {
  margin-top: 10px;
}

.article_single04 .article_single04_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single04 .article_single04_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single04 .slick-next,
.article_single04 .slick-prev {
  z-index: 1;
}

.article_single04 .slick-next:before,
.article_single04 .slick-prev:before {
  content: none;
}

.article_single04 .slick-next:after,
.article_single04 .slick-prev:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 35px;
  height: 35px;
  border-top: 3px solid #bcd63f;
  transition: 0.2s;
  z-index: 1;
}

.article_single04 .slick-prev {
  left: 0;
}

.article_single04 .slick-prev:after {
  left: 0;
  border-left: 3px solid #bcd63f;
  transform: translate(0, -50%) rotate(-45deg);
  transform-origin: right top;
}

.article_single04 .slick-next {
  right: 0;
}

.article_single04 .slick-next:after {
  right: 0;
  border-right: 3px solid #bcd63f;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
}

.article_single04 .ofi {
  background: rgba(188, 214, 63, 0.1);
  height: auto !important;
}

.article_single04 .more {
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .article_list_case02 .article_list_case02_01 {
    width: 67%;
  }
  .article_list_case02 .article_list_case02_02 {
    width: 31%;
  }
  .article_list_case02.article_single01 .article_single01_02 {
    margin-top: 0;
  }
}

.article_container .article_single01_02 {
  margin-top: 10px;
}

.article_container .article_single01_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_container .article_single01_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_detail {
  margin-bottom: 4%;
  font-size: 1.5rem;
}

.article_detail h2 {
  text-align: left;
  margin: 0 0 1em;
  padding: 0 0 .5em !important;
  font-size: 2.4rem;
  border-bottom: #bcd63f solid 2px;
}

@media (max-width: 767px) {
  .article_detail h2 {
    font-size: 1.9rem;
  }
}

.article_detail h2:after {
  content: none;
}

.article_detail h2 a:hover {
  text-decoration: none;
  color: #bcd63f;
}

.article_detail .span_12 {
  margin: 2% 0;
}

.article_detail .post_header .time:before {
  content: '\f073';
  font-family: "Font Awesome 5 Free";
  margin-right: .5em;
}

.article_detail .post_header .category_badge {
  background: #bcd63f;
  color: white;
  font-size: 1.4rem;
  margin-left: 1em;
  display: inline-block;
}

.article_detail .post_header .category_badge a {
  display: block;
  padding: .2em 1em;
  color: white;
  text-decoration: none;
}

.pager {
  overflow: hidden;
  margin-bottom: 5%;
}

.pager .pager_prev {
  float: left;
}

.pager .pager_prev a {
  display: block;
  position: relative;
  padding-left: 20px;
  text-decoration: none;
}

.pager .pager_prev a:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #bcd63f;
  border-left: solid 2px #bcd63f;
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 4px;
  margin-top: -6px;
}

.pager .pager_prev a:hover {
  opacity: 0.8;
}

.pager .pager_next {
  float: right;
}

.pager .pager_next a {
  display: block;
  position: relative;
  padding-right: 20px;
  text-decoration: none;
}

.pager .pager_next a:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #bcd63f;
  border-right: solid 2px #bcd63f;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -6px;
}

.pager .pager_next a:hover {
  opacity: 0.8;
}

.ofi {
  display: block;
  position: relative;
  overflow: hidden;
  padding-top: 75%;
}

.ofi.cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ofi > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.lead {
  color: #bcd63f;
  font-size: 2rem;
}

.text-xxlarge {
  font-size: 2.4rem;
}

.text-xlarge {
  font-size: 2rem;
}

.text-large {
  font-size: 1.6rem;
}

.text-small {
  font-size: 1.2rem;
}

.text-xsmall {
  font-size: 1rem;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #f00 !important;
}

.text-primary {
  color: #bcd63f !important;
}

@media (max-width: 767px) {
  .sp-hide, .pc-only, .tablet-only {
    display: none !important;
  }
  .sp-text-center {
    text-align: center;
  }
  .sp-text-left {
    text-align: left;
  }
  .sp-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .lead {
    font-size: 2.8rem;
  }
  .text-xxlarge {
    font-size: 3.6rem;
  }
  .text-xlarge {
    font-size: 2.8rem;
  }
  .text-large {
    font-size: 2rem;
  }
  .text-small {
    font-size: 1.2rem;
  }
  .text-xsmall {
    font-size: 1rem;
  }
  .pc-hide, .sp-only {
    display: none !important;
  }
  .pc-text-center {
    text-align: center;
  }
  .pc-text-left {
    text-align: left;
  }
  .pc-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .tablet-only {
    display: none !important;
  }
}

@media (max-width: 999px) {
  .sp-hide02, .pc-only02, .tablet-only02 {
    display: none !important;
  }
}

@media (min-width: 1000px) {
  .pc-hide02, .sp-only02 {
    display: none !important;
  }
}

.arial {
  font-family: 'Arial', arial !important;
}

.arial_n {
  font-family: 'Arial Narrow', arial !important;
}

.js-tel a {
  color: currentColor;
  text-decoration: none;
}

@media (min-width: 768px) {
  .js-tel a {
    pointer-events: none;
  }
}

.fs12 {
  font-size: 12px;
}

@media (max-width: 767px) {
  .sp-fs12 {
    font-size: 12px !important;
  }
}

.fs13 {
  font-size: 13px;
}

@media (max-width: 767px) {
  .sp-fs13 {
    font-size: 13px !important;
  }
}

.fs14 {
  font-size: 14px;
}

@media (max-width: 767px) {
  .sp-fs14 {
    font-size: 14px !important;
  }
}

.fs15 {
  font-size: 15px;
}

@media (max-width: 767px) {
  .sp-fs15 {
    font-size: 15px !important;
  }
}

.fs16 {
  font-size: 16px;
}

@media (max-width: 767px) {
  .sp-fs16 {
    font-size: 16px !important;
  }
}

.fs17 {
  font-size: 17px;
}

@media (max-width: 767px) {
  .sp-fs17 {
    font-size: 17px !important;
  }
}

.fs18 {
  font-size: 18px;
}

@media (max-width: 767px) {
  .sp-fs18 {
    font-size: 18px !important;
  }
}

.fs19 {
  font-size: 19px;
}

@media (max-width: 767px) {
  .sp-fs19 {
    font-size: 19px !important;
  }
}

.fs20 {
  font-size: 20px;
}

@media (max-width: 767px) {
  .sp-fs20 {
    font-size: 20px !important;
  }
}

.fs21 {
  font-size: 21px;
}

@media (max-width: 767px) {
  .sp-fs21 {
    font-size: 21px !important;
  }
}

.fs22 {
  font-size: 22px;
}

@media (max-width: 767px) {
  .sp-fs22 {
    font-size: 22px !important;
  }
}

.fs23 {
  font-size: 23px;
}

@media (max-width: 767px) {
  .sp-fs23 {
    font-size: 23px !important;
  }
}

.fs24 {
  font-size: 24px;
}

@media (max-width: 767px) {
  .sp-fs24 {
    font-size: 24px !important;
  }
}

.fs25 {
  font-size: 25px;
}

@media (max-width: 767px) {
  .sp-fs25 {
    font-size: 25px !important;
  }
}

.fs26 {
  font-size: 26px;
}

@media (max-width: 767px) {
  .sp-fs26 {
    font-size: 26px !important;
  }
}

.fs27 {
  font-size: 27px;
}

@media (max-width: 767px) {
  .sp-fs27 {
    font-size: 27px !important;
  }
}

.fs28 {
  font-size: 28px;
}

@media (max-width: 767px) {
  .sp-fs28 {
    font-size: 28px !important;
  }
}

.fs29 {
  font-size: 29px;
}

@media (max-width: 767px) {
  .sp-fs29 {
    font-size: 29px !important;
  }
}

.fs30 {
  font-size: 30px;
}

@media (max-width: 767px) {
  .sp-fs30 {
    font-size: 30px !important;
  }
}

.fs31 {
  font-size: 31px;
}

@media (max-width: 767px) {
  .sp-fs31 {
    font-size: 31px !important;
  }
}

.fs32 {
  font-size: 32px;
}

@media (max-width: 767px) {
  .sp-fs32 {
    font-size: 32px !important;
  }
}

.fs33 {
  font-size: 33px;
}

@media (max-width: 767px) {
  .sp-fs33 {
    font-size: 33px !important;
  }
}

.fs34 {
  font-size: 34px;
}

@media (max-width: 767px) {
  .sp-fs34 {
    font-size: 34px !important;
  }
}

.fs35 {
  font-size: 35px;
}

@media (max-width: 767px) {
  .sp-fs35 {
    font-size: 35px !important;
  }
}

.fs36 {
  font-size: 36px;
}

@media (max-width: 767px) {
  .sp-fs36 {
    font-size: 36px !important;
  }
}

.fs37 {
  font-size: 37px;
}

@media (max-width: 767px) {
  .sp-fs37 {
    font-size: 37px !important;
  }
}

.fs38 {
  font-size: 38px;
}

@media (max-width: 767px) {
  .sp-fs38 {
    font-size: 38px !important;
  }
}

.fs39 {
  font-size: 39px;
}

@media (max-width: 767px) {
  .sp-fs39 {
    font-size: 39px !important;
  }
}

.fs40 {
  font-size: 40px;
}

@media (max-width: 767px) {
  .sp-fs40 {
    font-size: 40px !important;
  }
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.m-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-5 {
  margin-top: 5em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.mb-4 {
  margin-bottom: 4em;
}

.mb-5 {
  margin-bottom: 5em;
}

.inverse h2,
.inverse h3,
.inverse h4,
.inverse p {
  color: #fff;
}

.inverse h2:after {
  background: #fff;
}

.inverse h2 small,
.inverse h2 span {
  color: #fff;
}

.image_right {
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .image_right {
    display: inline-block;
    float: right;
    margin-left: 1em;
  }
}

.dsp-home-only {
  display: none;
}

.dsp-under-only {
  display: block;
}

.home .dsp-home-only {
  display: block;
}

.home .dsp-under-only {
  display: none;
}

@media (max-width: 999px) {
  .targetOffset {
    scroll-margin-top: 60px;
  }
}

.icon-tel:before {
  content: '\f879';
  font-family: 'Font Awesome 5 Free';
  margin-right: 5px;
}

.fadeUp {
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  #a01, #a02, #a03, #a04, #a05, #a06, #a07, #a08, #a09, #a10, #a11, #a12, #a13, #a14, #a15 {
    padding-top: 60px;
    margin-top: -60px;
  }
}

.main_visual {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .main_visual .main_visual_copy > li img {
    max-width: 750px;
    max-height: 742px;
    left: 0;
    right: auto;
    width: 98%;
  }
}

@media screen and (min-width: 768px) {
  .main_visual .main_visual_copy > li img {
    width: 100%;
    bottom: 40%;
  }
}

@media screen and (min-width: 1250px) {
  .main_visual .main_visual_copy > li img {
    bottom: 20%;
  }
}

.sec_top {
  margin: 0 !important;
}

.sec_top h2 {
  text-align: center;
  font-size: 2.3rem;
  line-height: 1;
  font-weight: bold;
  padding: 0 0 15px;
  margin: 0 0 25px;
}

.sec_top h2 .sec_span {
  display: block;
  padding: 5px 0 0;
  font-size: 1.6rem;
  color: #bcd63f;
}

.sec_top h2:before, .sec_top h2:after {
  display: none;
}

.sec_top h2:after {
  content: '';
  position: absolute;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  width: 33px;
  height: 5px;
  background: url("/wp-content/uploads/h2.png") no-repeat center/cover;
}

.sec_top .button, .sec_top .inquiry .form_button, .inquiry .sec_top .form_button {
  font-size: 1.6rem;
  padding: 1.4em 1em;
  font-weight: bold;
  max-width: 248px;
}

@media screen and (min-width: 768px) {
  .sec_top h2 {
    font-size: 2.8rem;
    padding: 0 0 15px;
    margin: 0 0 50px;
  }
  .sec_top h2 .sec_span {
    padding: 5px 0 0;
    font-size: 2rem;
  }
}

@media screen and (min-width: 1250px) {
  .sec_top h2 {
    font-size: 3.2rem;
  }
}

.sec01 {
  padding: 50px 0;
  background: url("/wp-content/uploads/bg_img001.jpg") no-repeat center/cover;
}

.sec01 .sec_no {
  font-size: 3rem;
  line-height: 1;
  font-weight: bold;
  padding-bottom: 5px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #3f8a42;
  width: fit-content;
  text-align: center;
  color: #ffffff;
  letter-spacing: 4px;
  text-shadow: 1px 1px 0px #3f8a42, -1px 1px 0px #3f8a42, 1px -1px 0px #3f8a42, -1px -1px 0px #3f8a42, 1px 0px 0px #3f8a42, 0px 1px 0px #3f8a42, -1px 0px 0px #3f8a42, 0px -1px 0px #3f8a42;
}

.sec01 .sec_title {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.sec01 .sec_text {
  font-size: 1.6rem;
  line-height: 24px;
}

@media screen and (min-width: 768px) {
  .sec01 {
    padding: 100px 0;
  }
  .sec01 .sec_title {
    font-size: 1.6rem;
    margin-bottom: 15px;
    min-height: 48px;
  }
  .sec01 .sec_no {
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1020px) {
  .sec01 .sec_title {
    font-size: 1.8rem;
  }
}

.sec02 {
  padding: 50px 0;
  background: url("/wp-content/uploads/bg_img002.jpg") no-repeat center/cover;
}

@media screen and (min-width: 768px) {
  .sec02 {
    padding: 100px 0;
  }
}

.sec03 {
  padding: 50px 0;
}

.sec03 .sec_bnr a {
  max-width: 400px;
  height: 60px;
  margin: auto;
  font-size: 2rem;
  text-decoration: none;
  line-height: 1;
  font-weight: bold;
  border-radius: 15px;
  border: 2px solid #bcd63f;
  color: #bcd63f;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.sec03 .sec_bnr a:after {
  content: '';
  position: absolute;
  display: block;
  margin: auto;
  left: auto;
  right: 5%;
  top: 0;
  bottom: 0;
  width: 8px;
  height: 8px;
  border-right: 2px solid #bcd63f;
  border-top: 2px solid #bcd63f;
  transform: rotate(45deg);
}

.sec03 .sec_bnr a:hover {
  background: #bcd63f;
  border: 2px solid #bcd63f;
  color: #fff;
}

.sec03 .sec_bnr a:hover:after {
  border-color: #fff;
}

@media screen and (min-width: 768px) {
  .sec03 {
    padding: 100px 0;
  }
  .sec03 .row {
    max-width: 820px;
    margin: auto;
  }
  .sec03 .sec_bnr a {
    height: 100px;
    font-size: 2.4rem;
  }
}

.sec04 {
  padding: 50px 0;
  background: url("/wp-content/uploads/bg_img003.jpg") no-repeat center top;
}

.sec04 .sec_text {
  font-size: 1.6rem;
  line-height: 32px;
}

.sec04 .more_btn {
  margin-top: 20px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .sec04 h2 {
    line-height: 1.3;
  }
  .sec04 figure img {
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .sec04 {
    padding: 100px 0;
  }
  .sec04 .row {
    column-gap: 5%;
    align-items: center;
  }
  .sec04 .row .col:first-child {
    width: 49%;
  }
  .sec04 .row .col:last-child {
    width: 46%;
  }
  .sec04 .more_btn {
    margin-top: 50px;
    text-align: right;
  }
}

.sec05, .sec06 {
  padding: 50px 0;
}

.sec05 .more_btn, .sec06 .more_btn {
  margin-top: 25px;
}

.sec05 .content dl, .sec06 .content dl {
  width: 100%;
}

.sec05 .content dl dt, .sec06 .content dl dt {
  font-size: 1.6rem;
  color: #868686;
}

.sec05 .content dl dt span, .sec06 .content dl dt span {
  font-size: 1.3rem;
  background: #bcd63f;
  border: 1px solid #bcd63f;
  color: #fff;
  border-radius: 3px;
  vertical-align: middle;
}

.sec05 .content dl dt span:first-of-type, .sec06 .content dl dt span:first-of-type {
  padding-top: 0;
}

.sec05 .content dl dd, .sec06 .content dl dd {
  font-size: 1.6rem;
  position: relative;
}

.sec05 .content dl dd:after, .sec06 .content dl dd:after {
  content: '';
  position: absolute;
  display: block;
  margin: auto;
  left: auto;
  right: 2%;
  top: 0;
  bottom: 0;
  width: 8px;
  height: 8px;
  border-right: 2px solid #bcd63f;
  border-top: 2px solid #bcd63f;
  transform: rotate(45deg);
}

.sec05 .content dl dd a, .sec06 .content dl dd a {
  color: #2a2a2a;
}

@media screen and (max-width: 767px) {
  .sec05 dl dd, .sec06 dl dd {
    padding-right: 45px;
  }
}

@media screen and (min-width: 768px) {
  .sec05, .sec06 {
    padding: 100px 0;
  }
  .sec05 .more_btn, .sec06 .more_btn {
    margin-top: 50px;
  }
  .sec05 .content, .sec06 .content {
    width: 100%;
    padding: 0;
  }
  .sec05 .content dl, .sec06 .content dl {
    max-height: 450px;
  }
  .sec05 .content dl dd, .sec05 .content dl dt, .sec06 .content dl dd, .sec06 .content dl dt {
    font-size: 1.6rem;
    padding: 24px 0;
  }
  .sec05 .content dl dd:first-of-type, .sec05 .content dl dt:first-of-type, .sec06 .content dl dd:first-of-type, .sec06 .content dl dt:first-of-type {
    padding-top: 0;
  }
  .sec05 .content dl dt, .sec06 .content dl dt {
    margin-right: 10px;
    float: left;
    padding-left: 0;
  }
  .sec05 .content dl dt span, .sec06 .content dl dt span {
    max-width: 130px;
  }
  .sec05 .content dl dd, .sec06 .content dl dd {
    padding-right: 60px;
    padding-left: 210px;
  }
  .sec05 .content dl dd:first-of-type, .sec06 .content dl dd:first-of-type {
    border-top: none;
  }
}

.sec06 {
  padding-top: 0;
}

.sec07 {
  padding: 50px 0;
}

.sec07 .more_btn {
  margin-top: 25px;
}

.sec07 .sec_logo {
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 10px;
  font-weight: bold;
  font-family: "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
}

.sec07 dl {
  margin: 0 0 20px;
}

.sec07 dl dd {
  padding: 15px 0;
  line-height: 22px;
  border-top: 1px solid #d1d1d1;
  padding-left: 0;
  margin: 0;
}

.sec07 dl dd:first-of-type {
  border-top: 1px solid #d1d1d1;
}

.sec07 dl dd:last-of-type {
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .sec07 {
    position: relative;
    z-index: 0;
  }
  .sec07:after {
    content: '';
    position: absolute;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    width: 100%;
    height: 35%;
    background: url("/wp-content/uploads/bg_img002.jpg") no-repeat center top;
    z-index: -1;
  }
  .sec07 picture {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .sec07 {
    padding: 100px 0;
    background: url("/wp-content/uploads/bg_img002.jpg") no-repeat center top;
  }
  .sec07 .more_btn {
    margin-top: auto;
  }
  .sec07 .map_wide {
    padding-top: 494px;
  }
  .sec07 .row:not(:last-child) {
    margin-bottom: 50px;
  }
  .sec07 .sec_logo {
    font-size: 2.4rem;
    line-height: 1;
    margin-bottom: 10px;
    padding-top: 15px;
  }
  .sec07 .row {
    column-gap: 3.3%;
    justify-content: space-between;
  }
  .sec07 .row .span_6:first-child {
    width: 47.7%;
  }
  .sec07 .row .span_6:last-child {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
}

.sec08 {
  padding: 50px 0;
  background: url("/wp-content/uploads/bg_img004.jpg") no-repeat center/cover;
}

.sec08 h2 .sec_span {
  color: #fff;
}

.sec08 h2:after {
  background: url("/wp-content/uploads/h2_2.png") no-repeat center/cover;
}

.sec08 .text_box {
  padding: 30px 15px;
  border-radius: 15px;
  background: #fff;
}

.sec08 .sec_title {
  padding: 0 0;
  background: #bcd63f;
  text-align: center;
  color: #fff;
  margin: 0;
  font-weight: bold;
}

.sec08 .sec_tel {
  padding: 10px 0;
  border-bottom: 1px solid #d2dbef;
}

.sec08 .sec_text {
  font-size: 1.6rem;
  line-height: 20px;
  margin: 10px 0 0;
  text-align: center;
}

.sec08 .sec_text .sec_span {
  font-size: 1.4rem;
}

@media screen and (min-width: 768px) {
  .sec08 {
    padding: 100px 0;
  }
  .sec08 .text_box {
    padding: 32px 15px;
  }
  .sec08 .sec_text {
    font-size: 1.6rem;
  }
  .sec08 .sec_text .sec_span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1020px) {
  .sec08 .text_box {
    padding: 32px;
  }
  .sec08 .sec_text {
    font-size: 1.8rem;
  }
  .sec08 .sec_text .sec_span {
    font-size: 1.4rem;
  }
}

.box-border {
  border: solid 2px #bcd63f;
  padding: 1.5em;
  background: #fff;
}

section.highlight {
  padding-left: 0;
  padding-right: 0;
}

section.highlight:last-child {
  margin-bottom: 0;
}

.sp_text {
  display: inline;
  padding: 0;
  height: 0;
}

@media screen and (min-width: 768px) {
  .sp_text {
    display: none;
  }
}

.flow-block {
  padding: 0 0 3em;
  position: relative;
}

.flow-block:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 0;
  right: 0;
  bottom: 15px;
  margin: 0 auto -30px;
  border: 30px solid transparent;
  border-top: 20px solid #bcd63f;
}

.flow-block:last-child:after {
  display: none;
}

.anchro_list {
  list-style: none;
  padding: 1.5em;
  border: double 3px #ccc;
}

.anchro_list li a {
  color: #3f8a42;
  text-decoration: none;
  padding-left: 30px;
  position: relative;
}

.anchro_list li a:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #3f8a42;
  position: absolute;
  top: .2em;
  left: 0;
}

.anchro_list li a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .anchro_list {
    display: flex;
    flex-wrap: wrap;
  }
  .anchro_list li {
    width: 48%;
  }
  .anchro_list li:nth-child(odd) {
    margin-right: 4%;
  }
  .anchro_list li:not(:nth-child(-n + 2)) {
    margin-top: 10px;
  }
}
