@charset "utf-8";

.sns_icons {
  display: table;
  @include list-horizontal();
  margin: auto;
  li {
    display: inline-block;
  }
  li:not(:last-child) {
    margin-right: 10px;
  }
  a {
    display: block;
    @include image-replace();
    width: 34px;
    height: 34px;
    background-repeat: no-repeat;
    background-size: contain;
    &.icon_facebook {
      background-image: url(../../../../../wp-content/uploads/icon_facebook.png);
    }
    &.icon_twitter {
      background-image: url(../../../../../wp-content/uploads/icon_twitter.png);
    }
    &.icon_line {
      background-image: url(../../../../../wp-content/uploads/icon_line.png);
    }
    &.icon_google {
      background-image: url(../../../../../wp-content/uploads/icon_google.png);
    }
    &.icon_instagram {
      background-image: url(../../../../../wp-content/uploads/icon_instagram.png);
    }
  }
}
footer .sns_icons {
  margin-top: 1.5em;
}

@media ( min-width : 768px ) {
  .sns_icons {
    margin: 0;
    &.center {
      margin: auto;
    }
    li:not(:last-child) {
      margin-right: 20px;
    }
    a {
      width: 68px;
      height: 68px;
    }
  }
  footer .sns_icons {
    margin-top: 30px;
  }
}