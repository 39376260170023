@charset "utf-8";

h1:not(.header_logo) {
  text-align: center;
  @include fontsize(40);
  font-weight: bold;
  padding: .7em 5%;
  color: $color_h1_text;
  background: url("/wp-content/uploads/h1.jpg") no-repeat center / cover;
  margin: 0 0 40px;
  @if $fixed_header == true {
    @media (min-width: $header_break_point+px) {
      margin-top: $header_height+px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }

  @media (max-width: ($header_break_point - 1)+px) {
    margin-top: $sp_header_height+px;
  }

  span {
    display: block;
    @include fontsize(16);
    padding-top: .5em;
    color: lighten($color_h1_text, 10%);
    font-style: italic;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 767px) {
  h1:not(.header_logo) {
    @include fontsize(24);

    span {
      @include fontsize(14);
    }
  }
}

h2 {
  display: block;
  margin: 0 auto 1em;
  position: relative;
  text-align: center;
  border: 0;
  font-weight: bold;
  padding: 0 0 .5em;
  @include fontsize(30);
  color: $color_text;

  a {
    text-decoration: none;
    color: $color_h2_text;

    &:hover {
      text-decoration: underline;
      color: $color_h2_text;
    }
  }

  span {
    display: block;
    @include fontsize(14);
    padding-top: 1em;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 60px;
    right: 0;
    left: 0;
    bottom: 0;
    top: auto;
    margin: auto;
    background: $color_h2_border;
  }
}

@media (max-width: 767px) {
  h2 {
    @include fontsize(24);

    &:after {
      width: 20px;
    }
  }
}

h3 {
  margin: 0 0 1em;
  padding-bottom: 0.5em;
  color: $color_h3_text;
  @include fontsize(24);
  position: relative;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: rgba($color_h2_border, 0.2);
  }
}

@media (max-width: 767px) {
  h3 {
    @include fontsize(20);
  }
}

h4 {
  margin: 0 0 1em;
  padding-left: 1em;
  @include fontsize(18);
  position: relative;
  color: $color_h4_text;
  font-weight: bold;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    display: block;
    width: 5px;
    height: 1em;
    margin: auto;
    background: $color_primary;
  }
}

@media (max-width: 767px) {
  h4 {
    @include fontsize(14);
  }
}

//サイドバー
.widget_wrap {
  .widget_header {
    @include fontsize(16);
    color: $color_text;
    padding: .5em 0 .5em .5em;
    //background: $color_highlight_bg;
    border-bottom: $color_primary solid 2px;
    font-weight: bold;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 767px) {
  .widget_wrap {
    .widget_header {
      @include fontsize(14);
    }
  }
}
