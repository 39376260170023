@charset "utf-8";

.news {

  .heading{
    @media ( max-width : 767px ) {
      display: flex;
      justify-content: space-between;
      h2{
        text-align: left;
        margin: 0 0 1em;
        &:after{
          right: auto;
        }
      }
    }
  }

  dl {
    margin: 0;
    overflow-y: auto;
    max-height: 300px;

    dt {
      margin: 0.5em 0 0;
      padding: 0 1em;
      font-weight: normal;
    }

    dd {
      margin: 0.5em 0;
      padding: 0 1em 0.5em;
      border-bottom: #ccc solid 1px;
      position: relative;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .badge_category {
      display: inline-block;
      background: $color_primary;
      color: white;
      padding: .2em 1em;
      margin-left: 1em;
      @include fontsize(14);
    }
  }
}

@media (min-width: 768px) {
  .news {
    > div {
      display: flex;
      width: 100%;
      margin: 0 auto;

      .heading {
        width: 23.5%;
      }
    }

    h2 {
      margin: 0;
      padding: 0;

      &:after {
        display: none;
      }
    }

    .content {
      overflow: hidden;
      width: 76.5%;
      padding-left: 2%;
    }

    dl {
      overflow-y: auto;
      max-height: 200px;

      dt {
        margin: 0;
        padding: 1em 0 .5em 0;
      }

      dd {
        margin: 0;
        padding: .5em 0 1em 0;
      }
    }
  }
}
