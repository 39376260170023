@charset "utf-8";
@use "sass:math";
$grid_unit: (1 - ($grid_column-gap * 11)) / 12;

@media (max-width: 767px) {
  .sp_image_cols {
      display: flex;
      flex-wrap: wrap;
      column-gap: percentage($grid_column-gap);
      row-gap: $grid_row-gap+px;

    &.sp_col2 { // 2カラム
      .col {
        width: percentage(($grid_unit * 6) + ($grid_column-gap * 5));
      }
    }

    &.sp_col3 { // 3カラム
      display: flex;
      flex-wrap: wrap;

      .col {
        width: 33.3333333333%;
        padding: 0 1%;
      }
    }
  }
}
